import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Box, Grid, Typography } from '@material-ui/core';

import ContentItem from '../../components/ContentItem';

const ProfilePosts = ({ view, posts }) => {
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box py={4} minHeight="80vh">
      {Array.isArray(posts) && posts.length > 0 ? (
        <Grid container spacing={3} justify={smAndDown ? 'center' : undefined}>
          {posts.map(post => (
            <ContentItem
              key={`post_${post.id}`}
              type="post"
              variant={view}
              data={post}
            />
          ))}
        </Grid>
      ) : (
        <Typography align="center">
          {`You will see the user's uploads here once they upload something.`}
        </Typography>
      )}
    </Box>
  );
};

ProfilePosts.propTypes = {
  view: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.object),
};

ProfilePosts.defaultProps = {
  posts: [],
};

export default ProfilePosts;
