import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { MYCOLUMBA_SELECT_VALUES } from '../../constants';

import { useMediaQuery, Container, Box, Typography } from '@material-ui/core';

import Header from '../../components/Header';
import Select from '../../components/inputs/Select';
import ViewToggle from '../../components/ViewToggle';
import UploadButton from './UploadButton';
import Content from './Content';

const MyColumba = ({ currentUser }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selected, setSelected] = useState(MYCOLUMBA_SELECT_VALUES[0].value);
  const handleSelectedChange = e => setSelected(e.target.value);

  const [view, setView] = useState(isMobile ? 'grid' : 'list');
  const handleViewChange = newView => setView(newView);

  return (
    <div>
      <Helmet>
        <title>{'My Columba'}</title>
      </Helmet>

      <Header user={currentUser} />

      <Box py={4}>
        <Container fixed>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography className={classes.pageTitle}>
                {'My Columba'}
              </Typography>
              <Typography>
                {
                  'See everything you have favourited and uploaded across Columba'
                }
              </Typography>
            </Box>

            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={logBoundaryError}
            >
              {!isMobile ? <UploadButton /> : null}
            </ErrorBoundary>
          </Box>
        </Container>
      </Box>

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <Box py={2} bgcolor="#f8f8f8">
          <Container fixed>
            <Box
              display="flex"
              justifyContent={isMobile ? 'space-between' : 'flex-end'}
              alignItems="center"
            >
              {isMobile ? <UploadButton size="small" /> : null}

              <Select
                name="myColumbaSelect"
                value={selected}
                onChange={handleSelectedChange}
                options={MYCOLUMBA_SELECT_VALUES}
              />

              {!isMobile ? (
                <ViewToggle ml={4} value={view} onChange={handleViewChange} />
              ) : null}
            </Box>

            <Content filter={selected} view={view} />
          </Container>
        </Box>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
});

export default connect(mapStateToProps)(MyColumba);
