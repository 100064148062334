import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { dotify } from '../../../../utils/functions';

import {
  useMediaQuery,
  Box,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';

const ImageUploadItem = ({ upload, imageSource, activityName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isGrid: isMobile });

  const date = useMemo(() => {
    return moment(upload.updated_at).format('Do MMMM YYYY');
  }, [upload]);

  return (
    <Link
      to={{
        pathname: `/posts/${upload.slug}`,
        state: { isUpload: true, imgSrc: imageSource, activityName },
      }}
      className={classes.root}
    >
      <Card className={classes.card}>
        <CardActionArea className={classes.cardActionArea}>
          <CardMedia image={imageSource} className={classes.cardImage} />

          <CardContent className={classes.cardContent}>
            <Box>
              <Typography gutterBottom className={classes.postTitle}>
                {dotify(upload.title, isMobile ? 30 : 65)}
              </Typography>

              <Typography
                color="textSecondary"
                className={classes.postSubtitle}
              >
                {dotify(upload.content, isMobile ? 40 : 75)}
              </Typography>
            </Box>

            <Box mt={2}>
              <Typography className={classes.date}>{date}</Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

ImageUploadItem.propTypes = {
  upload: PropTypes.object.isRequired,
  imageSource: PropTypes.string.isRequired,
  activityName: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textDecoration: 'none',
  },
  card: {
    height: '100%',
  },
  cardActionArea: ({ isGrid }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: isGrid ? 'column' : 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }),
  cardImage: ({ isGrid }) => ({
    height: isGrid ? 200 : 'auto',
    width: isGrid ? 'auto' : 450,
  }),
  cardContent: ({ isGrid }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    ...(!isGrid
      ? {
          padding: theme.spacing(4),
          width: '100%',
        }
      : null),
  }),
  postTitle: {
    overflow: 'hidden',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
  },
  postSubtitle: {
    overflow: 'hidden',
    fontSize: '18px',
  },
  date: {
    fontSize: '15px',
  },
}));

export default ImageUploadItem;
