export const blue = '#60c9e9';
export const lightBlue = '#A2DEFA';
export const progressBlue = '#63CCE9';
export const milk = '#F9F9F9';
export const grey = '#D8D8D8';
export const lightGrey = '#EDF1F4';
export const darkGrey = '#979797';
export const green = '#44D7B6';

export const brandColors = {
  violet: '#7860A2',
  red: '#E53267',
  orange: '#F49F28',
  green: '#DCDB0F',
  blue: '#6AC6DF',
  lightBlue: '#B8E2F6',
};
