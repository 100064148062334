import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import MarkdownParser from '../../components/utils/MarkdownParser';

import { Popper, IconButton, Typography } from '@material-ui/core';
import HeartIcon from '@material-ui/icons/Favorite';

const Highlightable = ({
  content,
  highlights,
  postId,
  highlightText,
  unhighlightText,
}) => {
  const classes = useStyles();

  const [selection, setSelection] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const resetSelection = () => setSelection(null);

  const onHighlightClick = useCallback(
    highlightId => event => {
      unhighlightText(postId, highlightId);
    },
    [postId, unhighlightText]
  );

  useEffect(() => {
    const highlightedItems = document.getElementsByClassName('user-highlight');
    for (const item of highlightedItems) {
      item.addEventListener('click', onHighlightClick(item.getAttribute('id')));
    }

    return () => {
      const highlightedItems = document.getElementsByClassName(
        'user-highlight'
      );
      for (const item of highlightedItems) {
        item.removeEventListener(
          'click',
          onHighlightClick(item.getAttribute('id'))
        );
      }
    };
  }, [highlights, onHighlightClick]);

  const onMouseUp = () => {
    const selection = window.getSelection();

    // check for valid selection
    if (!selection || selection.anchorOffset === selection.focusOffset) {
      resetSelection();
      return;
    }
    if (
      selection.anchorNode === null ||
      selection.anchorNode.nodeType !== Node.TEXT_NODE
    ) {
      resetSelection();
      return;
    }
    if (
      !selection.anchorNode.data ||
      !selection.anchorNode.data.includes(selection.toString())
    ) {
      resetSelection();
      return;
    }

    const getBoundingClientRect = () =>
      selection.getRangeAt(0).getBoundingClientRect();

    setSelection(selection);
    setAnchorEl({
      clientWidth: getBoundingClientRect().width,
      clientHeight: getBoundingClientRect().height,
      getBoundingClientRect,
    });
  };

  const onHeartClick = () => {
    highlightText(postId, selection.toString(), selection.anchorNode.data);
    resetSelection();
  };

  const alreadyHighlighted = useMemo(() => {
    if (!Array.isArray(highlights) || !selection) return false;

    const existingHighlight = highlights.find(highlight => {
      if (!highlight.highlightText) return false;

      return (
        highlight.highlightContext.includes(selection.anchorNode.data) &&
        highlight.highlightText.includes(selection.toString())
      );
    });

    return Boolean(existingHighlight);
  }, [highlights, selection]);

  return (
    <>
      <div onMouseUp={onMouseUp}>
        <MarkdownParser content={content} />
      </div>

      <Popper open={Boolean(selection)} anchorEl={anchorEl}>
        <div className={classes.popover}>
          {selection && selection.toString().length < 10 ? (
            <Typography className={classes.popoverText}>
              {'Please select text with at least the length of 10'}
            </Typography>
          ) : alreadyHighlighted ? (
            <Typography className={classes.popoverText}>
              {'You already highlighted this text'}
            </Typography>
          ) : (
            <IconButton onClick={onHeartClick} className={classes.heartButton}>
              <HeartIcon className={classes.heartIcon} />
            </IconButton>
          )}
        </div>
      </Popper>
    </>
  );
};

Highlightable.propTypes = {
  postId: PropTypes.number.isRequired,
  content: PropTypes.string,
  highlights: PropTypes.array.isRequired,
  highlightText: PropTypes.func.isRequired,
  unhighlightText: PropTypes.func.isRequired,
};

Highlightable.defaultProps = {
  content: '',
};

const useStyles = makeStyles(theme => ({
  popover: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    borderRadius: '4px',
  },
  popoverText: {
    color: 'white',
    fontSize: '12px',
    fontWeight: theme.typography.fontWeightBold,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  heartButton: {
    '& svg': {
      opacity: '80%',
    },
    '&:hover': {
      '& svg': {
        opacity: '100%',
      },
    },
  },
  heartIcon: {
    color: 'red',
  },
}));

export default Highlightable;
