import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { studentTabs, teacherTabs } from '../../config/tabs.config';

import { dotify } from '../../utils/functions';

import { getPost } from '../../store/actions/postActions';
import {
  highlightText,
  unhighlightText,
} from '../../store/actions/userActions';

import { Container } from '@material-ui/core';

import PDFViewer from '../../components/utils/PDFViewer';
import Header from '../../components/Header';
import PostHeader from '../../components/PostHeader';
import MarkdownParser from '../../components/utils/MarkdownParser';
import Tag from '../../components/Tag';
import Loader from '../../components/Loader';

import PostComments from './PostComments';
import Highlightable from './Highlightable';

const PostPage = ({
  currentUser,
  highlights: allHighlights,
  post,
  getPost,
  highlightText,
  unhighlightText,
}) => {
  const { slug } = useParams();

  let categoryData = null;
  if (post && Array.isArray(post.categories)) {
    categoryData = teacherTabs[post.categories && post.categories[0].name]
      ? teacherTabs[post.categories && post.categories[0].name]
      : studentTabs[post.categories && post.categories[0].name];
  }

  const color = categoryData?.color;

  const classes = useStyles({ color });
  const history = useHistory();
  const state = history.location.state;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getPost(slug);

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [slug]);

  const isHTLA =
    currentUser?.role?.type === 'htla_member' ||
    currentUser?.role?.type === 'stla_member';

  const attachments = useMemo(() => {
    return post && Array.isArray(post.attachments)
      ? post.attachments.reduce(
          (res, doc) => {
            if (doc.ext === '.pdf') {
              res.pdf.push(doc);
            } else if (doc.mime.includes('image')) {
              res.img.push(doc);
            } else {
              res.other.push(doc);
            }
            return res;
          },
          { pdf: [], img: [], other: [] }
        )
      : { pdf: [], img: [], other: [] };
  }, [post]);

  const tags = useMemo(() => {
    return post && typeof post.tags === 'string' && post.tags.length > 0
      ? post.tags.split(';')
      : [];
  }, [post]);

  const comments = useMemo(() => {
    return post && Array.isArray(post.comments)
      ? [...post.comments].sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
      : [];
  }, [post]);

  const postHighlights = useMemo(() => {
    if (!post) return [];

    return Array.isArray(allHighlights)
      ? allHighlights.filter(highlight => highlight.post?.id === post.id)
      : [];
  }, [post, allHighlights]);

  const parsedContents = useMemo(() => {
    if (!post) {
      return '';
    }
    if (!Array.isArray(postHighlights) || postHighlights.length < 1) {
      return post.content;
    }

    let parsedContents = post.content;
    if (typeof parsedContents === 'string') {
      postHighlights
        .sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        })
        .forEach(highlight => {
          if (
            highlight.id &&
            highlight.highlightContext &&
            typeof highlight.highlightContext === 'string' &&
            highlight.highlightText
          ) {
            parsedContents = parsedContents.replace(
              highlight.highlightContext,
              highlight.highlightContext.replace(
                highlight.highlightText,
                `<mark id="${highlight.id}" class="user-highlight ${classes.highlight}" style="background-color:${color};">${highlight.highlightText}</mark>`
              )
            );
          }
        });
    }

    return parsedContents;
  }, [post, postHighlights, color, classes]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{dotify(post.title, 10)}</title>
      </Helmet>

      <Header user={currentUser} transparentOnMobile />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <PostHeader post={post} state={state} />

        <Container fixed className={classes.contentContainer}>
          {post ? (
            isHTLA ? (
              <Highlightable
                postId={post.id}
                content={parsedContents}
                highlights={postHighlights}
                highlightText={highlightText}
                unhighlightText={unhighlightText}
              />
            ) : (
              <MarkdownParser content={post.content} />
            )
          ) : null}

          {attachments &&
            Array.isArray(attachments.img) &&
            attachments.img.map(img => (
              <img
                key={img.id}
                src={img.url}
                alt={'attachment'}
                className={classes.image}
              />
            ))}

          {attachments &&
            attachments.pdf.map(doc => (
              <div className={classes.pdfContainer} key={doc.id}>
                <PDFViewer url={doc.url} />

                <a href={doc.url} download>
                  {doc.name}
                </a>
              </div>
            ))}

          {attachments &&
            attachments.other.map(doc => (
              <div key={doc.id}>
                <a href={doc.url} download>
                  {doc.name}
                </a>
              </div>
            ))}

          <div className={classes.tagsContainer}>
            {tags.map(tag => (
              <Tag key={tag} name={tag} />
            ))}
          </div>

          {isHTLA ? (
            <PostComments postId={post.id} comments={comments} />
          ) : null}
        </Container>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
  },
  contentContainer: props => ({
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
    },
    '& ::selection': {
      background: props.color,
      color: theme.palette.common.white,
    },
  }),
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '27px',
  },
  pdfContainer: {
    marginBottom: '8px',
  },
  marked: props => ({
    backgroundColor: props.color,
  }),
  image: {
    minWidth: '40%',
    maxWidth: '70%',
    display: 'block',
    margin: '0 auto 10px auto',
  },
  highlight: {
    cursor: 'pointer',
    position: 'relative',
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  highlights: state.user.highlights,
  post: state.posts.post,
});

export default connect(mapStateToProps, {
  getPost,
  highlightText,
  unhighlightText,
})(PostPage);
