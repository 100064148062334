import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik, FastField } from 'formik';
import * as Yup from 'yup';

import { blue } from '../../config/colors.config';
import { filterImages } from '../../utils/functions';

import { getCategories } from '../../store/actions/postCategoryActions';
import { addPost } from '../../store/actions/postActions';

import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Cancel';

import FormikTextField from '../../components/utils/FormikTextField';
import Button from '../../components/Button';
import Checkbox from '../../components/inputs/Checkbox';
import Tag from '../../components/Tag';
import Dropzone from '../../components/inputs/Dropzone';
import FileCard from '../../components/FileCard';

const schema = Yup.object().shape({
  title: Yup.string().max(200).required(),
  subtitle: Yup.string().max(500),
  content: Yup.string().required(),
  shareWith: Yup.string().max(255).required(),
  timeToFinish: Yup.string().max(255),
  tags: Yup.string().max(500),
  categories: Yup.array()
    .of(Yup.number())
    .required('please select at least one category')
    .max(6, 'a maximum of 6 categories is allowed'),
});

const initialValues = {
  title: '',
  subtitle: '',
  content: '',
  shareWith: 'my_group',
  timeToFinish: '',
  tags: '',
  categories: [],
  cover: null,
  attachments: [],
};

const UploadForm = ({
  handleUploadModalClose,
  handleUploadModalSuccess,
  getCategories,
  categoriesLoading,
  categories,
  addPost,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    getCategories({ academy: 'htla' });
  }, [getCategories]);

  const [tag, setTag] = useState('');
  const handleTagReset = () => setTag('');
  const handleTagChange = e => setTag(e.target.value);

  const handleSubmit = async (values, { setSubmitting }) => {
    const uploaded = await addPost(values);

    setSubmitting(false);
    handleUploadModalSuccess(uploaded);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty, values, setFieldValue, errors, setErrors }) => (
        <Form noValidate>
          <Grid container spacing={2}>
            {/* TITLE */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>{'Title'}</Typography>
              <FastField
                name="title"
                type="text"
                placeholder="Title"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            {/* SUBTITLE */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Subtitle'}
              </Typography>
              <FastField
                name="subtitle"
                type="text"
                placeholder="Subtitle"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            {/* DESCRIPTION */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Description'}
              </Typography>
              <FastField
                name="content"
                type="text"
                placeholder="Write a detailed description"
                component={FormikTextField}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>

            {/* TIME TO FINISH */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Time to finish'}
              </Typography>
              <FastField
                name="timeToFinish"
                type="text"
                placeholder="Time to finish the article (e.g.: 10 minute read, 5 minute video etc.)"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>

            {/* SHARE WITH */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Share with'}
              </Typography>
              <Checkbox
                id="shareWith_me"
                name="shareWith"
                label="Me"
                checked
                disabled
              />
              <Checkbox
                id="shareWith_mygroup"
                name="shareWith"
                label="My Cohort"
                checked={
                  values.shareWith === 'everyone' ||
                  values.shareWith === 'my_group'
                }
                onChange={() => {
                  setFieldValue('shareWith', 'my_group');
                }}
              />
              <Checkbox
                id="shareWith_everyone"
                name="shareWith"
                label="Everyone"
                checked={values.shareWith === 'everyone'}
                onChange={() => {
                  setFieldValue('shareWith', 'everyone');
                }}
              />
            </Grid>

            {/* CATEGORY */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Category'}
              </Typography>
              {categoriesLoading ? (
                <Box py={1} display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : Array.isArray(categories) ? (
                categories.map(category => (
                  <Checkbox
                    key={`category_${category.id}`}
                    name={category.name}
                    label={
                      category.name.charAt(0).toUpperCase() +
                      category.name.slice(1)
                    }
                    checked={values.categories.includes(category.id)}
                    onChange={e => {
                      if (e.target.checked) {
                        setFieldValue('categories', [
                          ...values.categories,
                          category.id,
                        ]);
                      } else {
                        setFieldValue(
                          'categories',
                          [...values.categories].filter(
                            id => id !== category.id
                          )
                        );
                      }
                    }}
                  />
                ))
              ) : null}
              {errors.categories ? (
                <Typography color="error" className={classes.errorText}>
                  {errors.categories}
                </Typography>
              ) : null}
            </Grid>

            {/* COVER */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Cover image'}
                <span className={classes.fieldLabelInfo}>
                  {'(Images must be at least 512x512 pixels)'}
                </span>
              </Typography>

              {values.cover ? (
                <Box display="flex" justifyContent="center">
                  <div className={classes.uploadCoverContainer}>
                    <img
                      alt="Upload cover"
                      src={URL.createObjectURL(values.cover)}
                      className={classes.uploadCover}
                    />

                    <IconButton
                      className={classes.uploadCoverRemoveButton}
                      color="secondary"
                      onClick={() => setFieldValue('cover', null)}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </div>
                </Box>
              ) : (
                <Dropzone
                  height={225}
                  multiple={false}
                  accept="image/*"
                  onAccept={files => {
                    filterImages(files).then(filteredImages => {
                      if (filteredImages.length > 0) {
                        setFieldValue('cover', filteredImages[0]);
                      }
                    });
                  }}
                  onReject={rejections => {
                    if (!Array.isArray(rejections)) return;
                    rejections.forEach(rejection => {
                      setErrors({
                        cover: Array.isArray(rejection.errors)
                          ? rejection.errors[0].message
                          : null,
                      });
                    });
                  }}
                />
              )}

              {errors.cover ? (
                <Typography color="error" className={classes.errorText}>
                  {errors.cover}
                </Typography>
              ) : null}
            </Grid>

            {/* ATTACHMENTS */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Attachments'}
                <span className={classes.fieldLabelInfo}>
                  {'(Images must be at least 512x512 pixels)'}
                </span>
              </Typography>
              {Array.isArray(values.attachments) &&
              values.attachments.length > 0 ? (
                <Grid
                  container
                  spacing={1}
                  className={classes.attachmentsContainer}
                >
                  {values.attachments.map((file, idx) => (
                    <Grid
                      key={`${file.name}_${idx}`}
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                    >
                      <FileCard
                        height={140}
                        title={file.name}
                        onRemove={() => {
                          setFieldValue(
                            'attachments',
                            values.attachments.filter(
                              existingFile => existingFile.name !== file.name
                            )
                          );
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : null}
              <Dropzone
                height={140}
                onAccept={files => {
                  const images = [];
                  const other = [];
                  files.forEach(file => {
                    if (file.type.startsWith('image')) {
                      images.push(file);
                    } else {
                      other.push(file);
                    }
                  });
                  if (images.length > 0) {
                    filterImages(images).then(filteredImages => {
                      setFieldValue('attachments', [
                        ...values.attachments,
                        ...other,
                        ...filteredImages,
                      ]);
                    });
                  } else {
                    setFieldValue('attachments', [
                      ...values.attachments,
                      ...other,
                    ]);
                  }
                }}
                onReject={rejections => {
                  if (!Array.isArray(rejections)) return;
                  rejections.forEach(rejection => {
                    setErrors({
                      attachments: Array.isArray(rejection.errors)
                        ? rejection.errors[0].message
                        : null,
                    });
                  });
                }}
              />
              {errors.attachments ? (
                <Typography color="error" className={classes.errorText}>
                  {errors.attachments}
                </Typography>
              ) : null}
            </Grid>

            {/* TAGS */}
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>{'Tags'}</Typography>
              {values.tags.length > 0 ? (
                <Box mb={1}>
                  {values.tags.split(';').map(tag => (
                    <Tag
                      key={`tag_${tag}`}
                      name={tag}
                      onClick={() => {
                        setFieldValue(
                          'tags',
                          values.tags
                            .split(';')
                            .filter(existingTag => existingTag !== tag)
                            .join(';')
                        );
                      }}
                      icon={RemoveIcon}
                      iconColor={theme.palette.error.main}
                    />
                  ))}
                </Box>
              ) : null}
              <TextField
                type="text"
                placeholder="Hashtags"
                variant="outlined"
                fullWidth
                value={tag}
                onChange={handleTagChange}
                error={Boolean(errors.tags)}
                helperText={errors.tags}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={!tag}
                        onClick={() => {
                          const existingTags =
                            values.tags.length > 0
                              ? values.tags.split(';')
                              : [];
                          setFieldValue(
                            'tags',
                            existingTags.concat(tag).join(';')
                          );

                          handleTagReset();
                        }}
                        className={classes.addTagButton}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleUploadModalClose}
              variant="text"
              size="small"
            >
              {'Cancel'}
            </Button>

            <Button
              type="submit"
              variant="primary"
              size="small"
              disabled={isSubmitting || !dirty}
              isLoading={isSubmitting}
            >
              {'Upload'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

UploadForm.propTypes = {
  handleUploadModalClose: PropTypes.func.isRequired,
  handleUploadModalSuccess: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  fieldLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  fieldLabelInfo: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '12px',
    marginLeft: theme.spacing(1),
  },
  errorText: {
    marginTop: theme.spacing(1),
  },
  addTagButton: {
    color: blue,
  },
  attachmentsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  uploadCoverContainer: {
    height: 225,
    width: 400,
    position: 'relative',
  },
  uploadCover: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  uploadCoverRemoveButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}));

const mapStateToProps = state => ({
  categoriesLoading: state.postCategory.loading,
  categories: state.postCategory.categories,
});

export default connect(mapStateToProps, {
  getCategories,
  addPost,
})(UploadForm);
