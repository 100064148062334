import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import { blue } from '../config/colors.config';

import { ButtonBase, CircularProgress } from '@material-ui/core';

const Button = ({ children, fullWidth, variant, size, isLoading, ...rest }) => {
  const classes = useStyles({ fullWidth });

  return (
    <ButtonBase
      className={clsx(classes.root, classes[variant], classes[size])}
      {...rest}
    >
      {children}

      {isLoading ? (
        <CircularProgress
          color="inherit"
          size={15}
          thickness={5}
          className={classes.loader}
        />
      ) : null}
    </ButtonBase>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'text']),
  size: PropTypes.oneOf(['default', 'small']),
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  fullWidth: false,
  variant: 'primary',
  size: 'default',
  isLoading: false,
};

const useStyles = makeStyles(theme => ({
  root: ({ fullWidth }) => ({
    width: fullWidth ? '100%' : 'auto',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: '12px',
    '&:disabled': {
      opacity: 0.5,
    },
  }),
  primary: {
    color: theme.palette.common.white,
    backgroundColor: blue,
    boxShadow: '0px 25px 25px -20px rgba(96, 201, 233, 1)',
    '&:hover': {
      backgroundColor: lighten(blue, 0.1),
      boxShadow: '0px 25px 35px -20px rgba(96, 201, 233, 1)',
    },
  },
  text: {
    color: 'inherit',
  },
  default: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    fontSize: '18px',
  },
  small: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    fontSize: '18px',
  },
  loader: {
    marginLeft: theme.spacing(1.5),
  },
}));

export default Button;
