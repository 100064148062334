import { captureException } from '@sentry/react';

import {
  getTeacherUserProgress,
  getYplaUserProgress,
} from '../../utils/userProgress';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import {
  SET_CURRENT_USER,
  SET_AVATAR,
  SIGN_OUT,
  SET_FAVOURITES,
  SET_HIGHLIGHTS,
  SET_USER_PROGRESS,
  FAVOURITE_POST,
  UNFAVOURITE_POST,
  HIGHLIGHT_TEXT,
  UNHIGHLIGHT_TEXT,
} from '../actionTypes/userActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const signIn = userData => async dispatch => {
  try {
    const { data } = await ApiClient.post(`/auth/local`, userData);
    const { jwt } = data;

    localStorage.setItem('jwtToken', jwt);

    return dispatch(getUser());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const signUp = userData => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(userData.data));
    formData.append('files.avatar', userData.avatar);

    const res = await ApiClient.post(`/auth/local/register`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    const { jwt } = res.data;

    localStorage.setItem('jwtToken', jwt);

    return dispatch(getUser());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const updateProfile = userData => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(userData.data));
    formData.append('files.avatar', userData.avatar);

    const res = await ApiClient.put(`/users/me`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const signOut = () => async dispatch => {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('type');

  dispatch({
    type: SIGN_OUT,
  });
};

export const setAvatar = avatarUrl => async dispatch => {
  dispatch({
    type: SET_AVATAR,
    payload: avatarUrl,
  });
};

export const askForPasswordReset = userData => async dispatch => {
  try {
    await ApiClient.post(`/auth/forgot-password`, userData);
  } catch (error) {
    captureException(error);
  } finally {
    dispatch(
      enqueueSnackbar({
        message: 'We sent a reset password link to your email',
        options: {
          variant: 'success',
        },
      })
    );
  }
};

export const resetPassword = userData => async dispatch => {
  try {
    const res = await ApiClient.post(`/auth/reset-password`, userData);
    const { jwt } = res.data;

    localStorage.setItem('jwtToken', jwt);

    return dispatch(getUser());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const getUser = () => async dispatch => {
  try {
    const { data: user } = await ApiClient.get('/users/me');

    const { role, group } = user;

    // get HTLA member data
    if (role && (role.type === 'htla_member' || role.type === 'stla_member')) {
      let favourites;
      let highlights;
      let teacherTabsConfig;
      try {
        const [
          { data: favouritesResponse },
          { data: highlightsResponse },
          { data: teacherTabsConfigResponse },
        ] = await Promise.all([
          ApiClient.get('/users/me/favourites/posts'),
          ApiClient.get('/users/me/favourites/highlights'),
          ApiClient.get('/tabs-config/htla-stla'),
        ]);

        favourites = favouritesResponse;
        highlights = highlightsResponse;
        teacherTabsConfig = teacherTabsConfigResponse;
      } catch (error) {
        captureException(error);

        favourites = [];
        highlights = [];
        teacherTabsConfig = null;
      }

      dispatch({
        type: SET_FAVOURITES,
        payload: favourites,
      });
      dispatch({
        type: SET_HIGHLIGHTS,
        payload: highlights,
      });
      dispatch({
        type: SET_USER_PROGRESS,
        payload: getTeacherUserProgress(teacherTabsConfig),
      });
    }

    // get HTLA member data
    if (role && role.type === 'ypla_member') {
      dispatch({
        type: SET_USER_PROGRESS,
        payload: getYplaUserProgress(group.from),
      });
    }

    dispatch({
      type: SET_CURRENT_USER,
      payload: user,
    });

    return user;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const favouritePost = postId => {
  return async dispatch => {
    try {
      const response = await ApiClient.post(`/posts/${postId}/favourite`);

      dispatch({
        type: FAVOURITE_POST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const unfavouritePost = postId => {
  return async dispatch => {
    try {
      const response = await ApiClient.delete(`/posts/${postId}/unfavourite`);

      dispatch({
        type: UNFAVOURITE_POST,
        payload: response.data,
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const highlightText = (
  postId,
  highlightText,
  highlightContext,
  highlightFrom,
  highlightTo
) => {
  return async dispatch => {
    try {
      const response = await ApiClient.post(`/posts/${postId}/highlight`, {
        highlightText,
        highlightContext,
        highlightFrom,
        highlightTo,
      });

      dispatch({
        type: HIGHLIGHT_TEXT,
        payload: response.data,
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const unhighlightText = (postId, highlightId) => {
  return async dispatch => {
    try {
      const response = await ApiClient.delete(
        `/posts/${postId}/unhighlight/${highlightId}`
      );

      dispatch({
        type: UNHIGHLIGHT_TEXT,
        payload: response.data,
      });
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};
