import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToggleLayer, Arrow } from 'react-laag';
import _ from 'lodash';

import skyeImage from '../assets/img/skye.jpg';
import searchIcon from '../assets/img/searchIcon.svg';
import whiteLogo from '../assets/img/whiteLogo.svg';
import blackLogo from '../assets/img/blackLogo.svg';
import { lightGrey } from '../config/colors.config';

import { signOut } from '../store/actions/userActions';

import {
  Typography,
  MenuItem,
  MenuList,
  ListItemIcon,
  Hidden,
  IconButton,
  useMediaQuery,
  Box,
  DialogContent,
} from '@material-ui/core';
import { ExitToApp, Edit, Menu } from '@material-ui/icons';

import CloseIcon from '@material-ui/icons/Close';

import Avatar from './Avatar';
import Dialog from './Dialog';
import EditProfileForm from './EditProfileForm';

const Header = ({
  signOut,
  userProgress,
  user,
  isWelcome,
  transparentOnMobile,
}) => {
  const classes = useStyles({ isWelcome });
  const history = useHistory();
  const theme = useTheme();
  const xsAndDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => setIsModalOpen(false);
  const handleModalOpen = () => setIsModalOpen(true);

  const teacherLinks = [
    {
      path: '/htla',
      name: 'Home',
    },
    {
      path: '/htla/group',
      name: `${user && user?.group?.title}`,
    },
    {
      path: '/htla/mycolumba',
      name: 'My Columba',
    },
  ];

  const studentTeacherLinks = [
    {
      path: '/stla',
      name: 'Home',
    },
    {
      path: '/stla/group',
      name: `${user && user?.group?.title}`,
    },
    {
      path: '/stla/mycolumba',
      name: 'My Columba',
    },
  ];

  const studentLinks = [
    {
      path:
        userProgress && userProgress.currentPhase
          ? userProgress.currentPhase === 'phase1'
            ? '/ypla'
            : `/ypla/${userProgress.currentPhase}`
          : '/ypla',
      name: 'Home',
    },
    {
      path: '/ypla/group',
      name: 'My Group',
    },
  ];

  let links = [];

  switch (user?.role?.type) {
    case 'htla_member':
      links = teacherLinks;
      break;
    case 'stla_member':
      links = studentTeacherLinks;
      break;
    case 'ypla_member':
      links = studentLinks;
      break;
    default:
      links = [];
  }

  const handleSignOut = () => {
    history.push('/sign-in');
    signOut();
  };

  const color =
    isWelcome || (xsAndDown && transparentOnMobile) ? 'white' : 'black';

  return (
    <div
      className={`${isWelcome ? classes.image : classes.default} ${
        transparentOnMobile ? classes.transparentOnMobile : ''
      }`}
    >
      <div className={classes.container}>
        <Link to="/">
          <img
            alt="logo"
            src={color === 'black' ? blackLogo : whiteLogo}
            className={classes.logo}
          />
        </Link>
        <Hidden smDown>
          <nav>
            {links.map(link => (
              <Link
                to={link.path}
                className={`${classes.link} ${classes[color]}`}
                key={link.name}
              >
                {link.name}
              </Link>
            ))}
          </nav>
        </Hidden>
        <div className={classes.right}>
          <Link to="/search" className={classes.search}>
            <img alt="search" src={searchIcon} className={classes.searchImg} />
          </Link>
          <Hidden smDown>
            <div className={classes.line} style={{ backgroundColor: color }} />
          </Hidden>
          <ToggleLayer
            renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) =>
              isOpen && (
                <div
                  ref={layerProps.ref}
                  className={classes.layer}
                  style={{
                    ...layerProps.style,
                  }}
                >
                  <MenuList>
                    <MenuItem key={'Edit profile'} onClick={handleModalOpen}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <Edit fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Edit profile</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleSignOut} key={'Sign out'}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <ExitToApp fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Sign out</Typography>
                    </MenuItem>
                  </MenuList>
                  <Arrow
                    style={arrowStyle}
                    layerSide={layerSide}
                    backgroundColor="white"
                    roundness={0.5}
                  />
                </div>
              )
            }
            placement={{
              anchor: 'BOTTOM_RIGHT',
              autoAdjust: true,
              triggerOffset: 10,
            }}
            closeOnOutsideClick
          >
            {({ triggerRef, toggle }) => (
              <div ref={triggerRef} onClick={toggle} className={classes.avatar}>
                <Avatar
                  imgSrc={user?.avatar?.url}
                  size="medium"
                  borderColor={color}
                  initials={`${
                    _.isString(user.firstName) ? user.firstName.charAt(0) : ''
                  }${_.isString(user.lastName) ? user.lastName.charAt(0) : ''}`}
                />
              </div>
            )}
          </ToggleLayer>
          <Hidden mdUp>
            <div className={classes.line} style={{ backgroundColor: color }} />
            <ToggleLayer
              renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) =>
                isOpen && (
                  <div
                    ref={layerProps.ref}
                    className={classes.layer}
                    style={{
                      ...layerProps.style,
                    }}
                  >
                    <MenuList>
                      {links.map(link => (
                        <MenuItem key={link.name}>
                          <Link to={link.path} className={classes.menuLink}>
                            <Typography variant="inherit">
                              {link.name}
                            </Typography>
                          </Link>
                        </MenuItem>
                      ))}
                    </MenuList>
                    <Arrow
                      style={arrowStyle}
                      layerSide={layerSide}
                      backgroundColor="white"
                      roundness={0.5}
                    />
                  </div>
                )
              }
              placement={{
                anchor: 'BOTTOM_RIGHT',
                autoAdjust: true,
                triggerOffset: 10,
              }}
              closeOnOutsideClick
            >
              {({ triggerRef, toggle }) => (
                <IconButton
                  ref={triggerRef}
                  onClick={toggle}
                  className={classes[color]}
                  size="medium"
                  edge="end"
                >
                  <Menu />
                </IconButton>
              )}
            </ToggleLayer>
          </Hidden>
        </div>
      </div>
      {isWelcome && (
        <Typography align="center" className={classes.title}>
          Welcome {user && user.firstName}
        </Typography>
      )}
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        maxWidth={'sm'}
        fullWidth
        scroll="body"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.closeButton}
        >
          <IconButton onClick={handleModalClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <DialogContent
          classes={{ root: classes.dialogRoot }}
          className={classes.dialog}
        >
          <EditProfileForm close={handleModalClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.object,
  isWelcome: PropTypes.bool,
  transparentOnMobile: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
  container: props => ({
    display: 'flex',
    padding: props.isWelcome ? '15px 33px' : '11px 32px',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '7px 12px',
    },
  }),
  image: {
    backgroundImage: `url(${skyeImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '100% 0',
    width: '100%',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
    fontSize: '80px',
    padding: '102px 0 337px 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '31px',
      padding: '51px 0 99px 0',
    },
    textShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  },
  logo: {
    height: '68px',
    [theme.breakpoints.down('sm')]: {
      height: '34px',
    },
  },
  link: {
    display: 'inline-block',
    fontWeight: 'bold',
    textDecoration: 'none',
    padding: '9px',
    borderBottom: 'solid 3px transparent',
    '&:hover': {
      borderBottom: 'solid 3px',
    },
  },
  menuLink: {
    textDecoration: 'none',
    color: 'black',
  },
  white: {
    color: 'white',
  },
  black: {
    color: 'black',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  line: {
    width: '1px',
    height: '53px',
    marginLeft: '15px',
    marginRight: '15px',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      height: '32px',
    },
  },
  search: {
    lineHeight: 'normal',
    fontSize: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: '15px',
    },
  },
  searchImg: {
    [theme.breakpoints.down('sm')]: {
      height: '24px',
    },
  },
  listItemIcon: {
    minWidth: '30px',
  },
  layer: {
    backgroundColor: 'white',
    borderRadius: '5%',
  },
  avatar: {
    cursor: 'pointer',
  },
  default: {
    backgroundColor: lightGrey,
  },
  transparentOnMobile: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.5)',
      left: 0,
      top: 0,
      width: '100%',
    },
  },
  dialogRoot: {
    padding: 0,
  },
  dialog: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    zIndex: 1,
  },
}));

const mapStateToProps = state => ({
  userProgress: state.user.userProgress,
});

export default connect(mapStateToProps, {
  signOut,
})(Header);
