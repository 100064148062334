import axios from 'axios';

import store from '../store';
import { SIGN_OUT } from '../store/actionTypes/userActionTypes';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

client.interceptors.request.use(
  request => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return request;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('type');

      store.dispatch({
        type: SIGN_OUT,
      });
    }

    return Promise.reject(error);
  }
);

export default client;
