import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { setAvatar } from '../store/actions/userActions';

import { Add } from '@material-ui/icons';

const UploadButton = props => {
  const classes = useStyles();

  const handleFileChange = event => {
    props.setAvatar(event.target.files[0]);
  };
  const file = React.useRef(null);

  const onButtonClick = () => {
    file.current.click();
  };

  return (
    <div className={props.className}>
      <input
        accept="image/*"
        hidden
        type="file"
        ref={file}
        onChange={event => handleFileChange(event)}
      />
      <button className={classes.button} onClick={onButtonClick}>
        <Add fontSize={'large'} />
        <span>Upload</span>
        <span>Your Profile Picture </span>
      </button>
    </div>
  );
};

UploadButton.propTypes = {
  className: PropTypes.string,
};

const useStyles = makeStyles({
  button: {
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'dashed',
    height: '138px',
    width: '138px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

export default connect(null, {
  setAvatar,
})(UploadButton);
