import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';

import { studentTabs, teacherTabs } from '../../config/tabs.config';

import { dotify } from '../../utils/functions';

import {
  useMediaQuery,
  Box,
  Card,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';

import AvatarWithTitle from '../AvatarWithTitle';

const PostItem = ({ variant, post }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isGrid = useMemo(() => isMobile || variant === 'grid', [
    isMobile,
    variant,
  ]);
  const classes = useStyles({ isGrid });

  const imageSource = _.get(post, 'cover.url', null);

  let categoryData = null;
  if (post && Array.isArray(post.categories)) {
    categoryData = post?.categories[0]
      ? teacherTabs[post.categories[0].name]
        ? teacherTabs[post.categories[0].name]
        : studentTabs[post.categories[0].name]
      : null;
  }

  let authorInitials = '';
  if (_.isString(_.get(post, 'user.firstName'))) {
    authorInitials.concat(post.user.firstName.charAt(0));
  }
  if (_.isString(_.get(post, 'user.lastName'))) {
    authorInitials.concat(post.user.lastName.charAt(0));
  }

  const author = post?.user
    ? {
        fullName: `${_.toString(post.user.firstName)} ${_.toString(
          post.user.lastName
        )}`,
        initials: authorInitials,
        avatarUrl: post.user.avatar ? post.user.avatar.url : null,
      }
    : {
        fullName: 'Anonymous',
        initials: 'AN',
        avatarUrl: null,
      };

  const date = post ? moment(post.updated_at).format('Do MMMM YYYY') : null;

  if (!post) {
    return null;
  }

  return (
    <Link to={`/posts/${post.slug}`} className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea className={classes.cardActionArea}>
          {imageSource || categoryData?.defaultCover ? (
            <CardMedia
              image={imageSource || categoryData?.defaultCover}
              className={classes.cardImage}
            />
          ) : null}

          <CardContent className={classes.cardContent}>
            <Box>
              <Typography gutterBottom className={classes.postTitle}>
                {dotify(post.title, isMobile ? 30 : 50)}
              </Typography>

              <Typography
                color="textSecondary"
                className={classes.postSubtitle}
              >
                {dotify(post.subtitle, isMobile ? 40 : 60)}
              </Typography>
            </Box>

            <Box mt={2}>
              <AvatarWithTitle
                title={author.fullName ? `by ${author.fullName}` : ''}
                subtitle={
                  post.timeToFinish
                    ? `${date} \u25CF ${post.timeToFinish}`
                    : date
                }
                imgSrc={author.avatarUrl}
                initials={author.initials}
                size="small"
              />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

PostItem.propTypes = {
  variant: PropTypes.oneOf(['list', 'grid']).isRequired,
  post: PropTypes.object.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textDecoration: 'none',
  },
  card: {
    height: '100%',
  },
  cardActionArea: ({ isGrid }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: isGrid ? 'column' : 'row',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }),
  cardImage: ({ isGrid }) => ({
    height: isGrid ? 200 : 'auto',
    width: isGrid ? 'auto' : 450,
  }),
  cardContent: ({ isGrid }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    ...(!isGrid
      ? {
          padding: theme.spacing(4),
          width: '100%',
        }
      : null),
  }),
  postTitle: {
    overflow: 'hidden',
    fontSize: '24px',
    fontWeight: theme.typography.fontWeightBold,
  },
  postSubtitle: {
    overflow: 'hidden',
    fontSize: '18px',
  },
}));

export default PostItem;
