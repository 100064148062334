export const FALLBACK_ERROR_MSG =
  'An error has occurred while trying to complete your request.';

export const HTLA_POST_SELECT_VALUES = [
  {
    value: 'all',
    label: 'All Columbans',
  },
  {
    value: 'group',
    label: 'My Cohort',
  },
];

export const MYCOLUMBA_SELECT_VALUES = [
  {
    value: 'recent',
    label: 'Most Recent',
    url: '/users/me/mycolumba/all',
  },
  {
    value: 'uploads',
    label: 'My Uploads',
    url: '/users/me/posts',
  },
  {
    value: 'favourites',
    label: 'Favourites',
    url: '/users/me/favourites/posts',
  },
  {
    value: 'highlights',
    label: 'Highlights',
    url: '/users/me/favourites/highlights',
  },
  {
    value: 'all',
    label: 'All',
    url: '/users/me/mycolumba/all',
  },
];
