import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';

import { dotify } from '../../utils/functions';

import { Box, Typography, Grid } from '@material-ui/core';

import Avatar from '../../components/Avatar';
import ContentItem from '../../components/ContentItem';

const ChatItem = ({ data }) => {
  const classes = useStyles();

  const type = useMemo(() => data.chat_type, [data]);

  const author = useMemo(() => {
    return data.user;
  }, [data]);

  const initials = useMemo(() => {
    let initials = '';
    if (_.isString(author.firstName)) {
      initials.concat(author.firstName.charAt(0));
    }
    if (_.isString(author.lastName)) {
      initials.concat(author.lastName.charAt(0));
    }

    return initials;
  }, [author]);

  const date = useMemo(() => {
    return moment(data.created_at).format('DD/MM/YYYY');
  }, [data]);

  const attachments = useMemo(() => {
    return Array.isArray(data.attachments)
      ? data.attachments.reduce(
          (acc, curr) => {
            if (curr.mime && curr.mime.includes('image')) {
              return {
                ...acc,
                image: [...acc.image, curr],
              };
            } else {
              return {
                ...acc,
                other: [...acc.other, curr],
              };
            }
          },
          { image: [], other: [] }
        )
      : { image: [], other: [] };
  }, [data]);

  return (
    <Box my={4} display="flex">
      <Box pl={0.5} pr={2}>
        <Avatar
          size="extraSmall"
          imgSrc={author && author.avatar ? author.avatar.url : null}
          borderColor="black"
          initials={initials}
        />
      </Box>

      <Box flexGrow={1}>
        <Box mb={2}>
          <Typography component="span" className={classes.authorName}>
            {`${author.firstName} ${author.lastName} `}
          </Typography>

          {type === 'POST' ? (
            <Typography component="span">{'uploaded a new post'}</Typography>
          ) : null}
          {type === 'COMMENT' ? (
            <Typography component="span">
              {`commented on post ${data.post ? `(${data.post.title})` : ``}`}
            </Typography>
          ) : null}
          {type === 'MESSAGE' ? (
            <Typography component="span">{'commented'}</Typography>
          ) : null}

          <Typography component="span" className={classes.date}>
            {date}
          </Typography>
        </Box>

        {type === 'COMMENT' || type === 'MESSAGE' ? (
          <Box className={classes.message}>
            <Typography>{data.content}</Typography>

            {attachments.image.length > 0 ? (
              <Grid container spacing={2} className={classes.images}>
                {attachments.image.map(image => (
                  <Grid
                    key={image.id}
                    item
                    xs={6}
                    sm={3}
                    className={classes.imagePreviewContainer}
                  >
                    <a href={image.url} className={classes.imagePreviewLink}>
                      <img
                        alt="Message attachment preview"
                        src={image.url}
                        className={classes.imagePreview}
                      />
                    </a>
                  </Grid>
                ))}
              </Grid>
            ) : null}

            {attachments.other.length > 0 ? (
              <Box mt={2}>
                {attachments.other.map(attachment => (
                  <div key={attachment.id} className={classes.attachment}>
                    <a href={attachment.url} download>
                      {dotify(attachment.name, 150)}
                    </a>
                  </div>
                ))}
              </Box>
            ) : null}
          </Box>
        ) : null}
        {type === 'POST' ? (
          <Box maxWidth={300} pr={1}>
            <ContentItem variant="grid" forceCardView type="post" data={data} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

ChatItem.propTypes = {
  data: PropTypes.object.isRequired,
};

const useStyles = makeStyles(theme => ({
  authorName: {
    fontWeight: theme.typography.fontWeightBold,
  },
  date: {
    marginLeft: theme.spacing(2),
    opacity: '25%',
    fontSize: '12px',
  },
  message: {
    padding: theme.spacing(2),
    backgroundColor: '#f8f8f8',
  },
  images: {
    marginTop: theme.spacing(1),
  },
  imagePreviewContainer: {
    minHeight: '50px',
    maxHeight: '150px',
  },
  imagePreviewLink: {
    height: '100%',
    width: '100%',
  },
  imagePreview: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  attachment: {
    marginTop: theme.spacing(0.5),
  },
}));

export default ChatItem;
