import {
  SET_RESULTS,
  SET_HAS_MORE,
  SET_START,
} from '../actionTypes/searchActionTypes';

const initialState = {
  results: [],
  hasMore: false,
  start: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_RESULTS:
      if (action.payload.reset) {
        return {
          ...state,
          results: action.payload.results,
        };
      }

      return {
        ...state,
        results: [...state.results, ...action.payload.results],
      };

    case SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };

    case SET_START:
      return {
        ...state,
        start: action.payload,
      };

    default:
      return state;
  }
}
