import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';

import {
  useMediaQuery,
  Container,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';

import ContentItem from '../../components/ContentItem';

const SearchResults = ({ results, hasMoreResults, start, loadMoreResults }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <Container fixed>
        <Box pb={2}>
          <Typography className={classes.searchResultsTitle}>
            {'Search results'}
          </Typography>
        </Box>

        <InfiniteScroll
          hasMore={hasMoreResults}
          pageStart={start}
          loadMore={loadMoreResults}
          loader={<div key={0}>Loading...</div>}
        >
          <Grid
            container
            spacing={3}
            justify={smAndDown ? 'center' : undefined}
          >
            {Array.isArray(results) && results.length > 0 ? (
              results.map(post => (
                <ContentItem
                  key={`post_${post.id}`}
                  type="post"
                  variant="grid"
                  data={post}
                />
              ))
            ) : (
              <Box py={2} width="100%">
                <Typography align="center">{'No results.'}</Typography>
              </Box>
            )}
          </Grid>
        </InfiniteScroll>
      </Container>
    </div>
  );
};

SearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  start: PropTypes.number.isRequired,
  loadMoreResults: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {},
  searchResultsTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.25rem',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default SearchResults;
