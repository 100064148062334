import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import {
  useMediaQuery,
  Container,
  Grid,
  Box,
  InputBase,
  IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const SearchActions = ({
  searchTerm,
  handleSearchTermChange,
  handleSearchClose,
  handleSearch,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = e => {
    e.preventDefault();

    handleSearch(searchTerm);
  };

  return (
    <div className={classes.root}>
      <Container fixed className={classes.container}>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Box display="flex" justifyContent="center" width="100%">
              <SearchIcon
                fontSize={smAndDown ? 'default' : 'large'}
                className={classes.searchIcon}
              />
            </Box>
          </Grid>

          <Grid item xs={10}>
            <form noValidate onSubmit={handleSubmit}>
              <InputBase
                className={classes.searchInput}
                name="searchTerm"
                type="text"
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder="Start typing..."
                autoFocus
                fullWidth
              />
            </form>
          </Grid>

          <Grid item xs={1}>
            <Box display="flex" justifyContent="center" width="100%">
              <IconButton onClick={handleSearchClose}>
                <CloseIcon
                  fontSize={smAndDown ? 'default' : 'large'}
                  className={classes.closeIcon}
                />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

SearchActions.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  handleSearchTermChange: PropTypes.func.isRequired,
  handleSearchClose: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: '28px 0',
  },
  container: {
    [theme.breakpoints.only('sm')]: {
      maxWidth: '710px',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '1145px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1530px',
    },
  },
  searchIcon: {
    color: theme.palette.common.black,
  },
  searchInput: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  closeIcon: {
    color: theme.palette.common.black,
  },
}));

export default SearchActions;
