import { captureException } from '@sentry/react';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
} from '../actionTypes/postCategoryActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getCategories = ({ academy }) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_CATEGORIES,
      });

      const response = await ApiClient.get('/post-categories', {
        params: {
          academy,
        },
      });

      if (!response.data) {
        const error = new Error('Could not get post categories!');

        dispatch({
          type: GET_CATEGORIES_FAILURE,
          payload: error,
        });
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        });

        return;
      }

      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: response.data.sort((a, b) => {
          return a.id - b.id;
        }),
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORIES_FAILURE,
        payload: error,
      });

      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};
