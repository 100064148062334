import React from 'react';
import PropTypes from 'prop-types';

import {
  InputLabel,
  OutlinedInput,
  FormControl,
  IconButton,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const PasswordInput = ({ error, isConfirm, ...rest }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="outlined-adornment-password">
        {isConfirm ? 'Confirm password' : 'Password'}
      </InputLabel>
      <OutlinedInput
        {...rest}
        error={!!error}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={isConfirm ? 137 : 70}
      />
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  );
};

PasswordInput.propTypes = {
  error: PropTypes.any.isRequired,
  isConfirm: PropTypes.bool,
};

export default PasswordInput;
