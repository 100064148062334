import {
  GET_MYCOLUMBA,
  GET_MYCOLUMBA_SUCCESS,
  GET_MYCOLUMBA_FAILURE,
} from '../actionTypes/myColumbActionTypes';

const initialState = {
  results: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MYCOLUMBA:
      return {
        ...state,
        loading: true,
      };

    case GET_MYCOLUMBA_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload,
      };

    case GET_MYCOLUMBA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
