import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { brandColors } from '../config/colors.config';

import { Typography } from '@material-ui/core';

const randomColor = Object.values(brandColors)[
  Math.floor(Math.random() * Object.values(brandColors).length)
];

const Avatar = ({ imgSrc, borderColor, size, initials, color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.container}>
      {borderColor && (
        <div
          className={`${classes.hexagonWrapper} ${
            classes[size + 'HexagonWrapper']
          } ${classes[borderColor]}`}
        />
      )}
      {imgSrc ? (
        <div
          className={`${classes.hexagon} ${classes[size]}`}
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundPosition: 'center',
          }}
        />
      ) : (
        <div className={`${classes.hexagon} ${classes[size]}`}>
          <Typography className={classes.initials}>{initials}</Typography>
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  imgSrc: PropTypes.string,
  size: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  initials: PropTypes.string,
  color: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  hexagon: props => ({
    clipPath: 'polygon(0% 25%, 50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props.color ? props.color : randomColor,
  }),
  hexagonWrapper: {
    position: 'absolute',
    left: '-1px',
    top: '-1px',
    clipPath: 'polygon(0% 25%, 50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%)',
  },
  extraSmallHexagonWrapper: {
    height: '30px',
    width: '24px',
  },
  smallHexagonWrapper: {
    height: '40px',
    width: '34px',
  },
  mediumHexagonWrapper: {
    height: '54px',
    width: '46px',
    [theme.breakpoints.down('sm')]: {
      height: '32px',
      width: '27px',
    },
  },
  bigHexagonWrapper: {
    height: '138px',
    width: '126px',
  },
  extraSmall: {
    height: '28px',
    width: '22px',
    fontSize: '12px',
  },
  small: {
    height: '38px',
    width: '32px',
  },
  medium: {
    height: '52px',
    width: '44px',
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      width: '25px',
    },
  },
  big: {
    height: '136px',
    width: '124px',
  },
  black: {
    backgroundColor: 'black',
  },
  white: {
    backgroundColor: 'white',
  },
  initials: {
    fontWeight: 'bold',
  },
}));

export default Avatar;
