import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { lightBlue, progressBlue } from '../../../../config/colors.config';

import { Typography } from '@material-ui/core';

const ProgressBar = ({ progress }) => {
  const classes = useStyles({ progress });

  return (
    <div className={classes.container}>
      <div className={classes.overlay} />
      <div className={classes.progressContainer} />
      <div className={classes.textContainer}>
        <Typography className={classes.text}>
          <span className={classes.bold}>{`${progress}% `}</span>Complete
        </Typography>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  container: {
    width: '203px',
    height: '32px',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: lightBlue,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  progressContainer: props => ({
    backgroundColor: progressBlue,
    height: '100%',
    position: 'relative',
    width: `${props.progress}%`,
  }),
  textContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: '16px',
  },
});

export default ProgressBar;
