import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from './Avatar';

const AvatarWithTitle = ({ imgSrc, title, subtitle, initials, size }) => {
  const classes = useStyles({ size });

  return (
    <div className={classes.container}>
      <Avatar
        size={size}
        borderColor="black"
        imgSrc={imgSrc}
        initials={initials}
      />
      <div className={classes.titleContainer}>
        {title ? <span className={classes.title}>{title}</span> : null}
        {subtitle ? <span className={classes.subtitle}>{subtitle}</span> : null}
      </div>
    </div>
  );
};

AvatarWithTitle.propTypes = {
  imgSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  initials: PropTypes.string,
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '12px',
  },
  title: props => {
    switch (props.size) {
      case 'small':
        return {
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 'bold',
        };
      case 'medium':
      default:
        return {
          fontSize: '20px',
          lineHeight: '30px',
          fontWeight: 'bold',
        };
    }
  },
  subtitle: props => {
    switch (props.size) {
      case 'small':
        return {
          fontSize: '15px',
          lineHeight: '20px',
        };
      case 'medium':
      default:
        return {
          fontSize: '20px',
          lineHeight: '30px',
        };
    }
  },
});

export default AvatarWithTitle;
