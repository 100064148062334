import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { milk, grey } from '../../config/colors.config';
import { studentTabs, teacherTabs } from '../../config/tabs.config';

import { Hidden } from '@material-ui/core';

import Tab from './Tab';

const TabNavigation = ({ userProgress, activeTab, memberType, children }) => {
  const classes = useStyles();

  const tabs = useMemo(() => {
    return memberType === 'htla' || memberType === 'stla'
      ? teacherTabs
      : studentTabs;
  }, [memberType]);

  const isTabDisabled = useCallback(
    tabName => {
      if (!userProgress) {
        return false;
      }

      let enabledTabs = [];

      if (memberType === 'htla' || memberType === 'stla') {
        // always enable 1st tab for teachers (awareness)
        enabledTabs = ['awareness', ...userProgress.accessibleTabs];
      } else if (memberType === 'ypla') {
        enabledTabs = Array.isArray(userProgress.accessiblePhases)
          ? userProgress.accessiblePhases
          : ['phase1'];
      }

      if (!Array.isArray(enabledTabs)) {
        return false;
      }

      return !enabledTabs.includes(tabName);
    },
    [memberType, userProgress]
  );

  const tabsContainer = isMobile => {
    return (
      <div className={classes.tabsContainer}>
        {Object.values(tabs).map(tab => (
          <div
            className={`${classes.tab} ${
              tab.value === activeTab && !isMobile ? classes.activeTab : ''
            }`}
            key={tab.value}
          >
            <Tab
              name={tab.name}
              color={tab.color}
              icon={tab.icon}
              memberType={memberType}
              value={tab.value}
              isActive={isMobile ? false : tab.value === activeTab}
              isDisabled={isTabDisabled(tab.value)}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Hidden smDown>{tabsContainer(false)}</Hidden>

      <div
        className={classes.mainContainer}
        style={{ borderColor: tabs[activeTab].color }}
      >
        {children}
      </div>

      <Hidden mdUp>{tabsContainer(true)}</Hidden>
    </div>
  );
};

TabNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  memberType: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: milk,
    border: '8px solid',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 0,
    },
    display: 'flex',
    justifyContent: 'center',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '-173px',
    },
  },
  tab: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
    borderRight: `1px solid ${grey}`,
    '&:last-child': {
      border: 0,
    },
  },
  activeTab: {
    margin: ' 0 25px',
  },
}));

const mapStateToProps = state => ({
  userProgress: state.user.userProgress,
});

export default connect(mapStateToProps, null)(TabNavigation);
