import React from 'react';
import PropTypes from 'prop-types';

import { Box, ButtonBase } from '@material-ui/core';
import { List, Grid } from 'react-feather';

const ViewToggle = ({ value, onChange, ...rest }) => {
  return (
    <Box width={50} display="flex" justifyContent="space-between" {...rest}>
      <ButtonBase
        disabled={value === 'list'}
        onClick={() => onChange('list')}
        disableRipple
      >
        <List size={24} opacity={value === 'list' ? '100%' : '25%'} />
      </ButtonBase>

      <ButtonBase
        disabled={value === 'grid'}
        onClick={() => onChange('grid')}
        disableRipple
      >
        <Grid size={20} opacity={value === 'grid' ? '100%' : '25%'} />
      </ButtonBase>
    </Box>
  );
};

ViewToggle.propTypes = {
  value: PropTypes.oneOf(['list', 'grid']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ViewToggle;
