import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { dotify } from '../../../../utils/functions';

import {
  useMediaQuery,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';

const UploadItem = ({ upload, activityName }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const classes = useStyles({ isGrid: isMobile });

  return (
    <Link
      to={{
        pathname: `/posts/${upload.slug}`,
        state: { isUpload: true, activityName },
      }}
      className={classes.root}
    >
      <Card className={classes.card}>
        <CardActionArea className={classes.cardActionArea}>
          <CardContent className={classes.Grid}>
            <Box
              display="flex"
              justifyContent={isMobile ? 'flex-start' : 'space-between'}
            >
              <Typography className={classes.postInfo}>
                <b>
                  {dotify(
                    upload.title,
                    isMobile ? 30 : isLargeScreen ? 100 : 40
                  )}
                </b>
              </Typography>

              {!isMobile ? (
                <Typography className={classes.uploadInfo}>
                  {moment(upload.updated_at).format('Do MMMM YYYY')}
                </Typography>
              ) : null}
            </Box>

            <Box mt={2}>
              <Typography className={classes.uploadText}>
                {upload.content}
              </Typography>
            </Box>

            {isMobile ? (
              <Typography className={classes.uploadInfo}>
                {moment(upload.updated_at).format('Do MMMM YYYY')}
              </Typography>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

UploadItem.propTypes = {
  upload: PropTypes.object.isRequired,
  activityName: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textDecoration: 'none',
  },
  card: {
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  postInfo: ({ isGrid }) => ({
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    ...(isGrid ? { fontSize: '18px' } : null),
  }),
  uploadInfo: ({ isGrid }) => ({
    fontSize: '15px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    ...(isGrid ? { marginTop: theme.spacing(2) } : null),
  }),
  uploadText: ({ isGrid }) => ({
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    ...(isGrid ? { fontSize: '16px' } : null),
  }),
}));

export default UploadItem;
