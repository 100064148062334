import { captureException } from '@sentry/react';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import { CHAT_RESULTS_LIMIT } from '../../config/request.config';

import {
  GET_CHAT_CONTENTS,
  GET_CHAT_CONTENTS_SUCCESS,
  GET_CHAT_CONTENTS_FAILURE,
  RESET_QUERY,
  ADD_MESSAGE,
} from '../actionTypes/chatActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getChatContents = () => {
  return async (dispatch, getState) => {
    const start = getState().chat.start;

    try {
      dispatch({
        type: GET_CHAT_CONTENTS,
      });
      const response = await ApiClient.get('/users/me/mygroup', {
        params: {
          _start: start,
          _limit: CHAT_RESULTS_LIMIT,
        },
      });

      if (!response.data) {
        const error = new Error('Could not get My Columba contents!');

        dispatch({
          type: GET_CHAT_CONTENTS_FAILURE,
          payload: error,
        });
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        });

        return;
      }

      dispatch({
        type: GET_CHAT_CONTENTS_SUCCESS,
        payload: {
          reset: start === 0,
          results: response.data,
          start: start + CHAT_RESULTS_LIMIT,
          hasMore: response.data.length === CHAT_RESULTS_LIMIT,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_CHAT_CONTENTS_FAILURE,
        payload: error,
      });

      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const resetQuery = () => async dispatch => {
  dispatch({ type: RESET_QUERY });
};

export const sendChatMessage = ({ message, attachments }) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append(
        'data',
        JSON.stringify({
          content: message,
        })
      );
      if (Array.isArray(attachments)) {
        attachments.forEach(attachment => {
          formData.append('files.attachments', attachment);
        });
      }

      const response = await ApiClient.post('/users/me/mygroup', formData, {
        'Content-Type': 'multipart/form-data',
      });

      if (response.data) {
        dispatch({
          type: ADD_MESSAGE,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};
