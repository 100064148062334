import {
  GET_GROUP_MEMBERS,
  GET_GROUP_MEMBER,
} from '../actionTypes/groupActionTypes';

const initialState = {
  groupMembers: [],
  groupMember: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_GROUP_MEMBERS:
      return {
        ...state,
        groupMembers: action.payload,
      };

    case GET_GROUP_MEMBER:
      return {
        ...state,
        groupMember: action.payload,
      };

    default:
      return state;
  }
}
