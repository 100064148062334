import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/utils/ErrorFallback';
import { logBoundaryError } from '../utils/functions';

import welcomeImage from '../assets/img/welcomeImage.jpg';
import logo from '../assets/img/blackLogo.svg';

import { signUp } from '../store/actions/userActions';

import { Grid, Hidden } from '@material-ui/core';

import AuthForm from '../components/AuthForm';

class SignInPage extends React.Component {
  isHTLA =
    this.props.match.params.academy === 'htla' ||
    this.props.match.params.academy === 'stla';

  validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
    email: Yup.string().email().required().label('Email address'),
    school: Yup.string().required().label('School'),
    password: Yup.string()
      .required()
      .label('Password')
      .min(6, 'Minimum password length is at least a value of 6'),
    confirmPassword: Yup.string()
      .required()
      .label('Confirm password')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    ...(this.isHTLA
      ? { jobTitle: Yup.string().required().label('Job title') }
      : null),
  });
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    school: '',
    jobTitle: '',
    password: '',
    confirmPassword: '',
  };

  componentDidMount() {
    const { currentUser } = this.props;

    if (currentUser) {
      this.handleRedirection();
    }
  }

  onSubmit = async (values, { setSubmitting }) => {
    const { signUp } = this.props;

    let userData = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      school: values.school,
      password: values.password,
      ...(this.isHTLA ? { jobTitle: values.jobTitle } : null),
    };

    await signUp({ data: userData, avatar: this.props.avatar });
    setSubmitting(false);
    this.handleRedirection();
  };

  handleRedirection = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { classes, match } = this.props;

    return (
      <Grid container component="main" className={classes.container}>
        <Helmet>
          <title>{'Sign up'}</title>
        </Helmet>

        <Grid item md={5} className={classes.formContainer}>
          <img alt="logo" src={logo} className={classes.logo} />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={logBoundaryError}
          >
            <AuthForm
              validationSchema={this.validationSchema}
              initialValues={this.initialValues}
              onSubmit={this.onSubmit}
              title="Sign up"
              type="sign up"
              memberType={match.params.academy}
            />
          </ErrorBoundary>
        </Grid>
        <Hidden smDown>
          <Grid item md={7} className={classes.image} />
        </Hidden>
      </Grid>
    );
  }
}

const styles = {
  container: {
    minHeight: '100vh',
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 64px',
  },
  image: {
    backgroundImage: `url(${welcomeImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '100% 0',
    width: '100%',
  },
  logo: {
    marginBottom: '50px',
  },
};

const mapStateToProps = state => ({
  avatar: state.user.avatar,
  currentUser: state.user.currentUserData,
  userProgress: state.user.userProgress,
});

export default connect(mapStateToProps, {
  signUp,
})(withStyles(styles)(SignInPage));
