import {
  SET_CURRENT_USER,
  SET_AVATAR,
  SIGN_OUT,
  SET_HIGHLIGHTS,
  SET_FAVOURITES,
  SET_USER_PROGRESS,
  FAVOURITE_POST,
  UNFAVOURITE_POST,
  HIGHLIGHT_TEXT,
  UNHIGHLIGHT_TEXT,
} from '../actionTypes/userActionTypes';

const initialState = {
  currentUserData: null,
  avatar: null,
  highlights: [],
  favourites: [],
  userProgress: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUserData: action.payload,
      };

    case SET_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };

    case SET_FAVOURITES:
      return {
        ...state,
        favourites: action.payload,
      };

    case SET_HIGHLIGHTS:
      return {
        ...state,
        highlights: action.payload,
      };

    case FAVOURITE_POST:
      return {
        ...state,
        favourites: [...state.favourites, action.payload],
      };

    case UNFAVOURITE_POST:
      return {
        ...state,
        favourites: state.favourites.filter(
          favourite => favourite.id !== action.payload.id
        ),
      };

    case HIGHLIGHT_TEXT:
      return {
        ...state,
        highlights: [...state.highlights, action.payload],
      };

    case UNHIGHLIGHT_TEXT:
      return {
        ...state,
        highlights: state.highlights.filter(
          highlight => highlight.id !== action.payload.id
        ),
      };

    case SET_USER_PROGRESS:
      return {
        ...state,
        userProgress: action.payload,
      };

    case SIGN_OUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
