import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';

import { teacherTabs, studentTabs } from '../config/tabs.config';
import { milk } from '../config/colors.config';

import { Hidden, Typography, useMediaQuery } from '@material-ui/core';

import FavouriteIcon from './FavouriteIcon';
import Avatar from './Avatar';
import AvatarWithTitle from './AvatarWithTitle';

const PostHeader = ({ currentUser, post, state }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xsAndDown = useMediaQuery(theme.breakpoints.down('xs'));

  const categoryData = useMemo(() => {
    if (!post || !Array.isArray(post.categories)) {
      return null;
    }

    return teacherTabs[post.categories && post.categories[0].name]
      ? teacherTabs[post.categories && post.categories[0].name]
      : studentTabs[post.categories && post.categories[0].name];
  }, [post]);

  const coverImage = useMemo(() => {
    if (state && state.imgSrc) {
      return state.imgSrc;
    } else if (post.cover) {
      return post.cover.url;
    } else {
      return categoryData ? categoryData.defaultCover : null;
    }
  }, [post, state, categoryData]);

  const authorImage = useMemo(() => {
    return post.user && post.user.avatar ? post.user.avatar.url : null;
  }, [post]);

  const initials = useMemo(() => {
    const author = post?.user;

    let initials = '';
    if (_.isString(author.firstName)) {
      initials.concat(author.firstName.charAt(0));
    }
    if (_.isString(author.lastName)) {
      initials.concat(author.lastName.charAt(0));
    }

    return initials;
  }, [post]);

  const avatarTitle = useMemo(() => {
    if (!post.user) return '';

    return `by ${_.toString(post.user.firstName)} ${_.toString(
      post.user.lastName
    )}`;
  }, [post]);

  const avatarSubtitle = useMemo(() => {
    return `${moment(post.updated_at).format(
      'Do MMMM YYYY'
    )} \u25CF ${_.toString(post.timeToFinish)}`;
  }, [post]);

  if (!post) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <img alt="post cover" src={coverImage} className={classes.image} />
      </div>

      <div className={classes.rightContainer}>
        <div>
          <Hidden xsDown>
            <div className={classes.categoryContainer}>
              <Avatar
                size={'small'}
                color={categoryData && categoryData.color}
              />

              <div className={classes.categoryText}>
                <span>From&nbsp;</span>
                <span className={classes.categoryName}>
                  {state && state.activityName
                    ? state.activityName
                    : categoryData && categoryData.name}
                </span>
                <span>&nbsp;value</span>
              </div>
            </div>
          </Hidden>

          <div className={classes.textContainer}>
            <Typography className={classes.title}>{post.title}</Typography>
            <Typography className={classes.subtitle}>
              {post.subtitle}
            </Typography>
          </div>
        </div>

        {!(state && state.isUpload) && (
          <div className={classes.authorContainer}>
            <div>
              <AvatarWithTitle
                title={avatarTitle}
                subtitle={avatarSubtitle}
                imgSrc={authorImage}
                initials={initials}
                size={xsAndDown ? 'small' : 'medium'}
              />
            </div>

            {currentUser &&
            currentUser.role &&
            (currentUser.role.type === 'htla_member' ||
              currentUser.role.type === 'stla_member') ? (
              <FavouriteIcon id={post.id} />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

PostHeader.propTypes = {
  post: PropTypes.object.isRequired,
  state: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      minHeight: '420px',
    },
  },
  leftContainer: {
    height: '300px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      width: '40%',
    },
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '100px 45px 55px 45px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: '-60px auto 24px auto',
      width: 'calc(100% - 32px)',
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: milk,
      position: 'relative',
      marginBottom: '85px',
      width: '60%',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '38px',
    lineHeight: '50px',
    [theme.breakpoints.up('md')]: {
      fontSize: '52px',
      lineHeight: '71px',
    },
  },
  categoryName: {
    fontWeight: 'bold',
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '& span': {
      fontSize: '20px',
      lineHeight: '27px',
    },
  },
  categoryText: {
    marginLeft: '8px',
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '20px',
    marginTop: '8px',
  },
  textContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: '16px 32px',
      marginBottom: '12px',
      backgroundColor: milk,
    },
  },
  authorContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '60px',
      position: 'absolute',
      bottom: '-30px',
      left: 0,
      paddingLeft: '45px',
      paddingRight: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: '150px',
    },
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
});

export default connect(mapStateToProps)(PostHeader);
