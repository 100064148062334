import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Container, Typography, ButtonBase } from '@material-ui/core';
import SadIcon from '@material-ui/icons/SentimentDissatisfied';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';

const ErrorFallback = ({ error }) => {
  const classes = useStyles();

  const [showDetailedError, setShowDetailedError] = useState(false);

  const toggleDetailedError = () => setShowDetailedError(!showDetailedError);

  return (
    <Box
      py={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.root}
    >
      <Container fixed>
        <SadIcon className={classes.errorIcon} />

        <Typography component="h1" className={classes.generalMessageTitle}>
          {
            'It looks like there has been an error while trying to fulfill your request. Sorry about that!'
          }
        </Typography>
        <Typography component="h2" className={classes.generalMessageSubtitle}>
          {'Maybe try refreshing the page?'}
        </Typography>

        <Box p={2} className={classes.errorDetails}>
          <ButtonBase
            onClick={toggleDetailedError}
            className={classes.toggleErrorDetails}
            disableRipple
            disableTouchRipple
          >
            {showDetailedError ? <ArrowDownIcon /> : <ArrowRightIcon />}

            <Typography component="span" className={classes.showMoreTitle}>
              {showDetailedError
                ? 'Hide detailed error message'
                : 'Show detailed error message'}
            </Typography>
          </ButtonBase>

          {showDetailedError ? (
            <Typography component="p" className={classes.errorMessage}>
              {error.message}
            </Typography>
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  errorIcon: {
    marginBottom: theme.spacing(4),
    fontSize: '96px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '72px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '48px',
    },
  },
  generalMessageTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2.25rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  generalMessageSubtitle: {
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  errorDetails: {
    borderRadius: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  toggleErrorDetails: {
    padding: theme.spacing(1),
    textAlign: 'left',
    width: '100%',
    fontSize: '16px',
  },
  showMoreTitle: {
    marginLeft: theme.spacing(2),
    fontSize: 'inherit',
  },
  errorMessage: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    fontSize: '16px',
    textAlign: 'left',
  },
}));

export default ErrorFallback;
