import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import { Box, Typography } from '@material-ui/core';

import Button from '../components/Button';

const NoAccess = props => {
  const history = useHistory();
  const classes = useStyles(props);
  const goBack = () => history.goBack();

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{'404: Not found'}</title>
      </Helmet>

      <Typography className={classes.title}>
        We couldn't find the page you are looking for.
      </Typography>
      <Typography className={classes.subtitle}>
        Sorry, you don't have permissions to view this page or it doesn't exist.
      </Typography>
      <Box mt={4}>
        <Button onClick={goBack}>{'Go back'}</Button>
      </Box>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    padding: '20px',
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '36px',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  subtitle: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
}));

export default NoAccess;
