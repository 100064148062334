import { captureException } from '@sentry/react';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import { MYCOLUMBA_SELECT_VALUES } from '../../constants';

import {
  GET_MYCOLUMBA,
  GET_MYCOLUMBA_SUCCESS,
  GET_MYCOLUMBA_FAILURE,
} from '../actionTypes/myColumbActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getMyColumba = ({ filter = 'recent' }) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_MYCOLUMBA,
      });

      const url = MYCOLUMBA_SELECT_VALUES.find(select => {
        return select.value === filter;
      }).url;
      const response = await ApiClient.get(url);

      if (!response.data) {
        const error = new Error('Could not get My Columba contents!');

        dispatch({
          type: GET_MYCOLUMBA_FAILURE,
          payload: error,
        });
        enqueueSnackbar({
          message: error.message,
          options: {
            variant: 'error',
          },
        });

        return;
      }

      dispatch({
        type: GET_MYCOLUMBA_SUCCESS,
        payload: response.data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        }),
      });
    } catch (error) {
      dispatch({
        type: GET_MYCOLUMBA_FAILURE,
        payload: error,
      });

      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};
