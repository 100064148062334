import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, FastField } from 'formik';
import * as Yup from 'yup';

import { filterImages } from '../../../utils/functions';

import { Box, Typography, Grid } from '@material-ui/core';

import FormikTextField from '../../../components/utils/FormikTextField';
import Button from '../../../components/Button';
import Dropzone from '../../../components/inputs/Dropzone';
import FileCard from '../../../components/FileCard';

const schema = Yup.object().shape({
  title: Yup.string().max(200).required(),
  content: Yup.string().required().required(),
});

const initialValues = {
  title: '',
  content: '',
  attachments: [],
};

const UploadForm = ({ handleUploadModalClose, onSubmit }) => {
  const classes = useStyles();

  const handleSubmit = async (values, { setSubmitting }) => {
    await onSubmit(values);

    setSubmitting(false);
    handleUploadModalClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, dirty, values, setFieldValue, errors, setErrors }) => (
        <Form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>{'Title'}</Typography>
              <FastField
                name="title"
                type="text"
                placeholder="Title"
                component={FormikTextField}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Description'}
              </Typography>
              <FastField
                name="content"
                type="text"
                placeholder="Write a detailed description"
                component={FormikTextField}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.fieldLabel}>
                {'Attachments'}
                <span className={classes.fieldLabelInfo}>
                  {'(Images must be at least 512x512 pixels)'}
                </span>
              </Typography>
              {Array.isArray(values.attachments) &&
              values.attachments.length > 0 ? (
                <Grid
                  container
                  spacing={1}
                  className={classes.attachmentsContainer}
                >
                  {values.attachments.map((file, idx) => (
                    <Grid
                      key={`${file.name}_${idx}`}
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={2}
                    >
                      <FileCard
                        height={140}
                        title={file.name}
                        onRemove={() => {
                          setFieldValue(
                            'attachments',
                            values.attachments.filter(
                              existingFile => existingFile.name !== file.name
                            )
                          );
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : null}
              <Dropzone
                height={140}
                onAccept={files => {
                  const images = [];
                  const other = [];
                  files.forEach(file => {
                    if (file.type.startsWith('image')) {
                      images.push(file);
                    } else {
                      other.push(file);
                    }
                  });
                  if (images.length > 0) {
                    filterImages(images).then(filteredImages => {
                      setFieldValue('attachments', [
                        ...values.attachments,
                        ...other,
                        ...filteredImages,
                      ]);
                    });
                  } else {
                    setFieldValue('attachments', [
                      ...values.attachments,
                      ...other,
                    ]);
                  }
                }}
                onReject={rejections => {
                  if (!Array.isArray(rejections)) return;
                  rejections.forEach(rejection => {
                    setErrors({
                      attachments: Array.isArray(rejection.errors)
                        ? rejection.errors[0].message
                        : null,
                    });
                  });
                }}
              />
              {errors.attachments ? (
                <Typography color="error" className={classes.errorText}>
                  {errors.attachments}
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          <Box mt={4} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleUploadModalClose}
              variant="text"
              size="small"
            >
              {'Cancel'}
            </Button>

            <Button
              type="submit"
              variant="primary"
              size="small"
              disabled={isSubmitting || !dirty}
              isLoading={isSubmitting}
            >
              {'Upload'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

UploadForm.propTypes = {
  handleUploadModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  fieldLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  fieldLabelInfo: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '12px',
    marginLeft: theme.spacing(1),
  },
  errorText: {
    marginTop: theme.spacing(1),
  },
  attachmentsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default UploadForm;
