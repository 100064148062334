import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import { teacherTabs, studentTabs } from '../../config/tabs.config';

import { Grid } from '@material-ui/core';

import HighlightItem from './HighlightItem';
import PostItem from './PostItem';

const ContentItem = ({ variant, forceCardView, type, data }) => {
  const classes = useStyles({ variant });

  const post = type === 'post' ? data : data.post;
  const date = data.created_at;
  const highlightText = type === 'highlight' ? data.highlightText : null;

  const highlightColors =
    post && Array.isArray(post.categories) && post.categories[0]
      ? {
          background: teacherTabs[post.categories[0].name]
            ? teacherTabs[post.categories[0].name].color
            : studentTabs[post.categories[0].name].color,
          text: teacherTabs[post.categories[0].name]
            ? teacherTabs[post.categories[0].name].textColor
            : studentTabs[post.categories[0].name].textColor,
        }
      : null;

  let item;
  if (type === 'post' && post) {
    item = <PostItem variant={variant} post={post} />;
  }
  if (type === 'favourite' && post) {
    item = <PostItem variant={variant} post={post} />;
  }
  if (type === 'highlight' && post) {
    item = (
      <HighlightItem
        variant={variant}
        postUrl={post?.slug}
        postTitle={post?.title}
        postAuthor={`${_.get(post, 'user.firstName', '')} ${_.get(
          post,
          'user.lastName',
          ''
        )}`}
        date={date}
        highlightText={highlightText}
        highlightColor={highlightColors?.background}
        highlightTextColor={highlightColors?.text}
      />
    );
  }

  if (!data || !item) {
    return null;
  }

  return (
    <Grid
      item
      className={classes.root}
      xs={12}
      {...(!forceCardView && variant === 'grid'
        ? {
            md: 6,
            lg: 4,
          }
        : null)}
    >
      {item}
    </Grid>
  );
};

ContentItem.propTypes = {
  variant: PropTypes.oneOf(['list', 'grid']).isRequired,
  forceCardView: PropTypes.bool,
  type: PropTypes.oneOf(['post', 'favourite', 'highlight']).isRequired,
  data: PropTypes.object.isRequired,
};

ContentItem.defaultProps = {
  forceCardView: false,
};

const useStyles = makeStyles(theme => ({
  root: ({ variant }) => ({
    ...(variant === 'grid'
      ? {
          [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
          },
        }
      : null),
  }),
}));

export default ContentItem;
