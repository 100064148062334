import {
  SET_POSTS,
  SET_HAS_MORE,
  SET_START,
  GET_POST,
  ADD_COMMENT,
} from '../actionTypes/postActionTypes';

const initialState = {
  posts: [],
  post: {},
  hasMore: false,
  start: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_POSTS:
      if (action.payload.reset) {
        return {
          ...state,
          posts: action.payload.data,
        };
      }

      return {
        ...state,
        posts: [...state.posts, ...action.payload.data],
      };

    case SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };

    case SET_START:
      return {
        ...state,
        start: action.payload,
      };

    case GET_POST:
      return {
        ...state,
        post: action.payload,
      };

    case ADD_COMMENT:
      return {
        ...state,
        post: {
          ...state.post,
          comments: [...state.post.comments, action.payload],
        },
      };

    default:
      return state;
  }
}
