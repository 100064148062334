import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { grey, milk } from '../../config/colors.config';

const Tab = ({
  isActive,
  isDisabled,
  color,
  icon,
  name,
  memberType,
  value,
  ...props
}) => {
  const classes = useStyles(props);
  const history = useHistory();

  const onClick = () => {
    if (!isDisabled) {
      history.push(
        `/${memberType}${
          value === 'awareness' || value === 'phase1' ? '' : `/${value}`
        }`
      );
    }
  };

  return (
    <div
      className={`${
        isActive
          ? classes.activeContainer
          : memberType === 'ypla'
          ? classes.studentContainer
          : ''
      } ${classes.container} ${isDisabled ? classes.disabled : ''}`}
      style={isActive ? { borderColor: color } : {}}
      onClick={onClick}
    >
      <img alt="logo" src={icon} className={classes.image} />
      {!isActive && <span className={classes.title}>{name}</span>}
    </div>
  );
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  memberType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    height: '63px',
    flexDirection: 'column',
    boxShadow: '1px',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      height: '173px',
      width: '173px',
      backgroundColor: milk,
    },
  },
  activeContainer: {
    transform: 'scale(1.3) translateY( -7.9%)',
    border: '6px solid',
    borderBottom: 0,
  },
  studentContainer: {
    [theme.breakpoints.up('lg')]: {
      width: '288px',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  image: {
    [theme.breakpoints.down('sm')]: {
      height: '36px',
    },
    height: '100px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '33px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
      lineHeight: '12px',
    },
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: grey,
      opacity: 1,
    },
  },
}));

export default Tab;
