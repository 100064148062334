import { get } from 'lodash';
import { captureException } from '@sentry/react';

import { FALLBACK_ERROR_MSG } from '../constants';

export const getErrorMessage = error => {
  const response = get(error, 'response.data.message');
  if (!response) return FALLBACK_ERROR_MSG;

  const messages = response[0].messages;
  if (!messages) return FALLBACK_ERROR_MSG;

  if (Array.isArray(messages)) {
    return messages.map(message => message.message).join(' ');
  } else {
    return messages;
  }
};

/**
 * Returns the dotified (short) version of a string.
 *
 * @param {string} string The string to dotify.
 * @param {number} maxLength The maximum length of the dotified string.
 */
export const dotify = (string = '', maxLength = 0) => {
  if (!maxLength) return string;
  if (!string) return '';

  const stringified = `${string}`;
  let sub = stringified.slice(0, maxLength);

  if (sub.length < stringified.length) {
    sub = sub.concat('...');
  }

  return sub;
};

export const filterImages = (files = []) => {
  if (files.length === 0) return files;

  return new Promise(resolve => {
    const filteredImages = [];
    let counter = 0;

    files.forEach(file => {
      const img = new Image();
      img.addEventListener('load', () => {
        if (img.width >= 512 && img.height >= 512) {
          filteredImages.push(file);
        }

        counter++;

        if (counter === files.length) {
          // completed processing all files
          resolve(filteredImages);
        }
      });

      img.src = URL.createObjectURL(file);
    });
  });
};

export const getContentTypeFromEntity = (entity = {}) => {
  if (!entity) return null;

  if (entity.post || entity.type) {
    return entity.type;
  }

  return 'post';
};

export const logBoundaryError = error => captureException(error);
