import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { SEARCH_RECENT_POSTS_LIMIT } from '../../config/request.config';
import { getRecentPosts, setStart } from '../../store/actions/postActions';

import {
  useMediaQuery,
  Container,
  Box,
  Typography,
  ButtonBase,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import ContentItem from '../../components/ContentItem';

const INITIAL_START = 0;

const RecentPosts = ({
  recentPosts,
  hasMorePosts,
  start,
  getRecentPosts,
  setStart,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [arePostsLoading, setArePostsLoading] = useState(true);

  const handleGetRecentPosts = useCallback(
    async start => {
      setStart(start);
      await getRecentPosts({ start });
    },
    [getRecentPosts, setStart]
  );

  useEffect(() => {
    let isMounted = true;

    (async () => {
      await handleGetRecentPosts(INITIAL_START);

      if (isMounted) setArePostsLoading(false);
    })();

    return () => {
      isMounted = false;
    };
  }, [handleGetRecentPosts]);

  return (
    <div className={classes.root}>
      <Container fixed>
        <Box display="flex" justifyContent="space-between" pb={2}>
          <Typography className={classes.recentlyAddedTitle}>
            {'Recently added'}
          </Typography>

          <ButtonBase
            onClick={() =>
              handleGetRecentPosts(start + SEARCH_RECENT_POSTS_LIMIT)
            }
            disabled={arePostsLoading || !hasMorePosts}
            classes={{
              root: classes.loadMoreButton,
              disabled: classes.loadMoreButtonDisabled,
            }}
          >
            {'See more'}
          </ButtonBase>
        </Box>

        {arePostsLoading ? (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid
            container
            spacing={3}
            justify={smAndDown ? 'center' : undefined}
          >
            {Array.isArray(recentPosts) && recentPosts.length > 0 ? (
              recentPosts.map(post => (
                <ContentItem
                  key={`post_${post.id}`}
                  type="post"
                  variant="grid"
                  data={post}
                />
              ))
            ) : (
              <Box py={2} width="100%">
                <Typography align="center">
                  {'No recent posts found.'}
                </Typography>
              </Box>
            )}
          </Grid>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = state => ({
  recentPosts: state.posts.posts,
  hasMorePosts: state.posts.hasMore,
  start: state.posts.start,
});

const useStyles = makeStyles(theme => ({
  root: {},
  recentlyAddedTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.25rem',
  },
  loadMoreButton: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.1rem',
    border: '1px solid black',
    borderRadius: '35px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  loadMoreButtonDisabled: {
    opacity: '50%',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

RecentPosts.propTypes = {
  recentPosts: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMorePosts: PropTypes.bool.isRequired,
  start: PropTypes.number.isRequired,
  getRecentPosts: PropTypes.func.isRequired,
  setStart: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getRecentPosts, setStart })(
  RecentPosts
);
