export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AVATAR = 'SET_AVATAR';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_FAVOURITES = 'SET_FAVOURITES';
export const SET_HIGHLIGHTS = 'SET_HIGHLIGHTS';
export const SET_USER_PROGRESS = 'SET_USER_PROGRESS';
export const FAVOURITE_POST = 'FAVOURITE_POST';
export const UNFAVOURITE_POST = 'UNFAVOURITE_POST';
export const HIGHLIGHT_TEXT = 'HIGHLIGHT_TEXT';
export const UNHIGHLIGHT_TEXT = 'UNHIGHLIGHT_TEXT';
