import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

// MUI
import { TextField } from '@material-ui/core';

/**
 * Wrapper for: TextField (MUI)
 * Required for: Formik
 *
 * Wrapper components for MUI input fields are required
 * to sync changes to the input field and to render
 * errors correctly.
 */
const FormikTextField = memo(props => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  const hasError =
    (touched[field.name] && Boolean(errors[field.name])) ||
    Boolean(getIn(errors, field.name));
  const errorText = errors[field.name] || getIn(errors, field.name);

  return (
    <TextField
      {...field}
      {...rest}
      error={hasError}
      helperText={hasError ? errorText : ''}
    />
  );
});

FormikTextField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default FormikTextField;
