import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({
  className,
  width,
  height,
  label,
  dropLabel,
  onAccept,
  onReject,
  maxFiles,
  maxSize,
  accept,
  disabled,
  multiple,
}) => {
  const classes = useStyles({ width, height, disabled });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: onAccept,
    ...(onReject ? { onDropRejected: onReject } : null),
    ...(maxFiles ? { maxFiles } : null),
    ...(maxSize ? { maxSize } : null),
    ...(accept ? { accept } : null),
    disabled,
    multiple,
  });

  return (
    <div {...getRootProps()} className={clsx(classes.root, className)}>
      <input {...getInputProps()} />
      {isDragActive ? dropLabel : label}
    </div>
  );
};

Dropzone.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.node,
  dropLabel: PropTypes.node,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

Dropzone.defaultProps = {
  className: '',
  label: (
    <p>
      Drag & drop or <u>browse</u>
    </p>
  ),
  dropLabel: <p>Drop files here</p>,
  maxSize: 20971520,
  disabled: false,
  multiple: true,
};

const useStyles = makeStyles(() => ({
  root: ({ width, height, disabled }) => ({
    width: width ? width : '100%',
    height: height ? height : '100px',
    border: '1px dashed #979797',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    ...(disabled
      ? {
          opacity: '50%',
          cursor: 'not-allowed',
          '&:hover': {
            cursor: 'not-allowed',
          },
        }
      : null),
  }),
}));

export default Dropzone;
