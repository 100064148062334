import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';

import { POSTS_LIMIT } from '../config/request.config';

import { setStart } from '../store/actions/postActions';

import { useMediaQuery, Grid } from '@material-ui/core';

import ContentItem from './ContentItem';

const CardsGrid = ({ data, loadMore, ...props }) => {
  const { hasMore, start, setStart } = props;
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const load = () => {
    const nextStart = start + POSTS_LIMIT;

    loadMore(nextStart);
    setStart(nextStart);
  };

  return (
    <InfiniteScroll
      pageStart={start}
      loadMore={load}
      hasMore={hasMore}
      loader={<div key={0}>Loading ...</div>}
    >
      <Grid container spacing={5} justify={smAndDown ? 'center' : undefined}>
        {data.map(post => (
          <ContentItem key={post.id} type="post" variant="grid" data={post} />
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

CardsGrid.propTypes = {
  data: PropTypes.array.isRequired,
  loadMore: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  hasMore: state.posts.hasMore,
  start: state.posts.start,
});

export default connect(mapStateToProps, { setStart })(CardsGrid);
