import { captureException } from '@sentry/react';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import {
  GET_GROUP_MEMBERS,
  GET_GROUP_MEMBER,
} from '../actionTypes/groupActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getGroupMembers = () => async dispatch => {
  try {
    const response = await ApiClient.get('/users/me/mygroup/members');

    dispatch({
      type: GET_GROUP_MEMBERS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const getGroupMember = userId => async dispatch => {
  try {
    const response = await ApiClient.get(`/users/me/mygroup/members/${userId}`);

    dispatch({
      type: GET_GROUP_MEMBER,
      payload: response.data,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};
