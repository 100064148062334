import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { Select as MuiSelect, MenuItem, InputBase } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Input = withStyles(() => ({
  root: {
    borderBottom: '4px solid #e2e100',
  },
  input: {},
}))(InputBase);

const Select = ({ name, value, onChange, options, ...rest }) => {
  const classes = useStyles();

  if (!Array.isArray(options)) {
    return null;
  }

  return (
    <MuiSelect
      name={name}
      value={value}
      onChange={onChange}
      input={<Input />}
      IconComponent={ExpandMoreIcon}
      {...rest}
    >
      {options.map(option => (
        <MenuItem
          key={`option_${option.value}`}
          value={option.value}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: '#e2e100',
    },
  },
  menuItemSelected: {
    backgroundColor: '#e2e100 !important',
  },
}));

export default Select;
