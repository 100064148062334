import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import _ from 'lodash';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { getGroupMember } from '../../store/actions/groupActions';

import { useMediaQuery, Box, Container } from '@material-ui/core';

import Loader from '../../components/Loader';
import Header from '../../components/Header';
import ViewToggle from '../../components/ViewToggle';

import ProfileHeader from './ProfileHeader';
import ProfilePosts from './ProfilePosts';

const UserProfile = ({ currentUser, getGroupMember, profile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id: userId } = useParams();

  const [loading, setLoading] = useState(true);

  const [view, setView] = useState(isMobile ? 'grid' : 'list');
  const handleViewChange = newView => setView(newView);

  useEffect(() => {
    (async () => {
      await getGroupMember(userId);

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [userId]);

  if (loading || !profile) {
    return <Loader />;
  }

  return (
    <div>
      <Helmet>
        <title>{`${profile.firstName} ${profile.lastName}`}</title>
      </Helmet>

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <Header user={currentUser} />

        <Box borderBottom="1px solid rgba(0, 0, 0, 0.12)">
          <Container fixed>
            <ProfileHeader
              avatarSrc={profile.avatar ? profile.avatar.url : null}
              initials={`${
                _.isString(profile.firstName) ? profile.firstName.charAt(0) : ''
              }${
                _.isString(profile.lastName) ? profile.lastName.charAt(0) : ''
              }`}
              fullName={`${profile.firstName} ${profile.lastName}`}
              description={`${profile.jobTitle} ${profile.school}`}
            />
          </Container>
        </Box>

        <Box bgcolor="#f9f9f9">
          <Container fixed>
            {!isMobile ? (
              <Box display="flex" justifyContent="flex-end" py={2}>
                <ViewToggle value={view} onChange={handleViewChange} />
              </Box>
            ) : null}

            <ProfilePosts view={view} posts={profile.posts} />
          </Container>
        </Box>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  profile: state.group.groupMember,
});

export default connect(mapStateToProps, { getGroupMember })(UserProfile);
