import { captureException } from '@sentry/react';
import { get } from 'lodash';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import {
  POSTS_LIMIT,
  SEARCH_RECENT_POSTS_LIMIT,
} from '../../config/request.config';

import {
  SET_POSTS,
  SET_START,
  SET_HAS_MORE,
  GET_POST,
  ADD_COMMENT,
} from '../actionTypes/postActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getPostsByCategory = ({ start, category, memberType, type }) => {
  return async dispatch => {
    try {
      dispatch(setHasMore(false));

      const res = await ApiClient.get(`/posts/${memberType}/${type}`, {
        params: {
          _sort: 'updated_at:DESC',
          _start: start,
          _limit: POSTS_LIMIT,
          category,
        },
      });

      dispatch({
        type: SET_POSTS,
        payload: {
          reset: start === 0,
          data: res.data,
        },
      });

      if (res.data.length === POSTS_LIMIT) {
        dispatch(setHasMore(true));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const getPost = slug => {
  return async dispatch => {
    try {
      const res = await ApiClient.get(`/posts/${slug}`);

      dispatch({
        type: GET_POST,
        payload: res.data,
      });

      return res.data;
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const getRecentPosts = ({ start }) => {
  return async (dispatch, getState) => {
    const memberType = get(getState().user.currentUserData, 'role.type');
    if (!memberType || typeof memberType !== 'string') {
      dispatch(
        enqueueSnackbar({
          message: 'Error: insufficient permissions (missing member type)',
          options: {
            variant: 'error',
          },
        })
      );
    }

    const academy = memberType.split('_')[0];

    try {
      dispatch(setHasMore(false));

      const res = await ApiClient.get(`/posts/${academy}/all`, {
        params: {
          _sort: 'updated_at:DESC',
          _start: start,
          _limit: SEARCH_RECENT_POSTS_LIMIT,
        },
      });

      dispatch({
        type: SET_POSTS,
        payload: {
          reset: start === 0,
          data: res.data,
        },
      });

      if (res.data.length === POSTS_LIMIT) {
        dispatch(setHasMore(true));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const commentOnPost = ({ postId, comment }) => async dispatch => {
  try {
    const response = await ApiClient.post('/comments', {
      postId,
      content: comment,
    });

    if (response.data) {
      dispatch({
        type: ADD_COMMENT,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const addPost = postData => async dispatch => {
  try {
    const { cover, attachments, ...data } = postData;

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('files.cover', cover);

    if (Array.isArray(attachments)) {
      attachments.forEach(attachment => {
        formData.append('files.attachments', attachment);
      });
    }

    const response = await ApiClient.post('/posts', formData, {
      'Content-Type': 'multipart/form-data',
    });

    return response.data;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const setStart = start => async dispatch => {
  dispatch({
    type: SET_START,
    payload: start,
  });
};

const setHasMore = value => {
  return {
    type: SET_HAS_MORE,
    payload: value,
  };
};
