import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
} from '../actionTypes/snackbarActionTypes';

const initialState = {
  notification: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notification: action.payload,
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notification: null,
      };

    default:
      return state;
  }
}
