import awareness from '../assets/img/tabs/awareness.svg';
import defaultAwareness from '../assets/img/tabs/defaultCovers/defaultAwareness.png';
import focus from '../assets/img/tabs/focus.svg';
import defaultFocus from '../assets/img/tabs/defaultCovers/defaultFocus.png';
import creativity from '../assets/img/tabs/creativity.svg';
import defaultCreativity from '../assets/img/tabs/defaultCovers/defaultCreativity.png';
import integrity from '../assets/img/tabs/integrity.svg';
import defaultIntegrity from '../assets/img/tabs/defaultCovers/defaultIntegrity.png';
import perseverance from '../assets/img/tabs/perseverance.svg';
import defaultPerseverance from '../assets/img/tabs/defaultCovers/defaultPerseverance.png';
import service from '../assets/img/tabs/service.svg';
import defaultService from '../assets/img/tabs/defaultCovers/defaultService.png';
import firstPhase from '../assets/img/tabs/phase1.svg';
import defaultFirstPhase from '../assets/img/tabs/defaultCovers/defaultAwareness.png';
import defaultSecondPhase from '../assets/img/tabs/defaultCovers/defaultFocus.png';
import defaultThirdPhase from '../assets/img/tabs/defaultCovers/defaultIntegrity.png';
import secondPhase from '../assets/img/tabs/phase2.svg';
import thirdPhase from '../assets/img/tabs/phase3.svg';
import alumni from '../assets/img/tabs/alumni.svg';
import defaultAlumni from '../assets/img/tabs/defaultCovers/defaultPerseverance.png';

import { brandColors } from './colors.config';

export const teacherTabs = {
  awareness: {
    name: 'Awareness',
    value: 'awareness',
    subtitle: 'Increase awareness of self and as a leader',
    icon: awareness,
    color: brandColors.green,
    textColor: '#000',
    defaultCover: defaultAwareness,
  },
  focus: {
    name: 'Focus',
    value: 'focus',
    subtitle: 'Increase awareness of self and as a leader',
    icon: focus,
    color: brandColors.blue,
    textColor: '#fff',
    defaultCover: defaultFocus,
  },
  creativity: {
    name: 'Creativity',
    value: 'creativity',
    subtitle: 'Increase awareness of self and as a leader',
    icon: creativity,
    color: brandColors.lightBlue,
    textColor: '#000',
    defaultCover: defaultCreativity,
  },
  integrity: {
    name: 'Integrity',
    value: 'integrity',
    subtitle: 'Increase awareness of self and as a leader',
    icon: integrity,
    color: brandColors.violet,
    textColor: '#fff',
    defaultCover: defaultIntegrity,
  },
  perseverance: {
    name: 'Perseverance',
    value: 'perseverance',
    subtitle: 'Increase awareness of self and as a leader',
    icon: perseverance,
    color: brandColors.red,
    textColor: '#fff',
    defaultCover: defaultPerseverance,
  },
  service: {
    name: 'Service',
    value: 'service',
    subtitle: 'Increase awareness of self and as a leader',
    icon: service,
    color: brandColors.orange,
    textColor: '#fff',
    defaultCover: defaultService,
  },
};

export const studentTabs = {
  phase1: {
    name: 'Phase 1',
    value: 'phase1',
    icon: firstPhase,
    color: brandColors.green,
    title: 'Phase One - Before your Leadership Intensive',
    subtitle: 'Everything you need to know before your Leadership Intensive',
    defaultCover: defaultFirstPhase,
  },
  phase2: {
    name: 'Phase 2',
    value: 'phase2',
    icon: secondPhase,
    color: brandColors.blue,
    title: 'Phase Two - During your Leadership Intensive',
    subtitle: 'Everything you need during your Leadership Intensive',
    defaultCover: defaultSecondPhase,
  },
  phase3: {
    name: 'Phase 3',
    value: 'phase3',
    icon: thirdPhase,
    color: brandColors.violet,
    title: 'Phase Three - After your Leadership Intensive',
    subtitle: 'What are your plans?',
    defaultCover: defaultThirdPhase,
  },
  alumni: {
    name: 'Alumni',
    value: 'alumni',
    icon: alumni,
    color: brandColors.red,
    title: 'Alumni Content',
    subtitle: "See what's happening across Columba 1400 alumni and schools",
    defaultCover: defaultAlumni,
  },
};
