import {
  createMuiTheme,
  unstable_createMuiStrictModeTheme,
} from '@material-ui/core/styles';

import { blue, brandColors } from '../config/colors.config';

const createTheme =
  process.env.NODE_ENV === 'production'
    ? createMuiTheme
    : unstable_createMuiStrictModeTheme;

const theme = createTheme({
  typography: {
    fontFamily: `'Open Sans', sans-serif`,
    fontSize: 14,
  },
  palette: {
    primary: {
      main: blue,
    },
    secondary: {
      main: brandColors.red,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
      },
    },
  },
});

export default theme;
