import moment from 'moment';

export const getTeacherUserProgress = tabsConfig => {
  if (!tabsConfig) {
    return {
      accessibleTabs: [
        'awareness',
        'focus',
        'creativity',
        'integrity',
        'perseverance',
        'service',
      ],
    };
  }

  const currentDate = moment();
  let {
    awareness_unlock_date,
    focus_unlock_date,
    creativity_unlock_date,
    integrity_unlock_date,
    perseverance_unlock_date,
    service_unlock_date,
  } = tabsConfig;
  awareness_unlock_date = moment(awareness_unlock_date, 'YYYY-MM-DD');
  focus_unlock_date = moment(focus_unlock_date, 'YYYY-MM-DD');
  creativity_unlock_date = moment(creativity_unlock_date, 'YYYY-MM-DD');
  integrity_unlock_date = moment(integrity_unlock_date, 'YYYY-MM-DD');
  perseverance_unlock_date = moment(perseverance_unlock_date, 'YYYY-MM-DD');
  service_unlock_date = moment(service_unlock_date, 'YYYY-MM-DD');

  const accessibleTabs = [];
  if (
    !awareness_unlock_date.isValid() ||
    currentDate.isAfter(awareness_unlock_date)
  ) {
    accessibleTabs.push('awareness');
  }
  if (!focus_unlock_date.isValid() || currentDate.isAfter(focus_unlock_date)) {
    accessibleTabs.push('focus');
  }
  if (
    !creativity_unlock_date.isValid() ||
    currentDate.isAfter(creativity_unlock_date)
  ) {
    accessibleTabs.push('creativity');
  }
  if (
    !integrity_unlock_date.isValid() ||
    currentDate.isAfter(integrity_unlock_date)
  ) {
    accessibleTabs.push('integrity');
  }
  if (
    !perseverance_unlock_date.isValid() ||
    currentDate.isAfter(perseverance_unlock_date)
  ) {
    accessibleTabs.push('perseverance');
  }
  if (
    !service_unlock_date.isValid() ||
    currentDate.isAfter(service_unlock_date)
  ) {
    accessibleTabs.push('service');
  }

  return {
    accessibleTabs,
  };
};

export const getYplaUserProgress = groupStartDate => {
  if (!groupStartDate) {
    return {
      currentPhase: 'phase1',
      accessiblePhases: ['phase1'],
    };
  }

  const currentDate = moment();
  const startDate = moment(groupStartDate, 'YYYY-MM-DD');

  let currentPhase = 'phase1';
  const accessiblePhases = ['phase1'];
  // group has started, give access to phase 2
  if (currentDate.isAfter(startDate)) {
    currentPhase = 'phase2';
    accessiblePhases.push('phase2');

    // 7 days has passed since group start, give access to phase 3 & alumni
    if (currentDate.isAfter(startDate.add(7, 'days'))) {
      currentPhase = 'phase3';
      accessiblePhases.push('phase3');
      accessiblePhases.push('alumni');
    }
  }

  return {
    currentPhase,
    accessiblePhases,
  };
};
