import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { ButtonBase } from '@material-ui/core';

const Tag = ({ name, onClick, icon: Icon, iconColor }) => {
  const classes = useStyles({ iconColor });
  const history = useHistory();

  const handleTagClick = useCallback(() => {
    history.push(`/search/${name}`);
  }, [history, name]);

  return (
    <ButtonBase
      onClick={onClick ? onClick : handleTagClick}
      className={classes.root}
    >
      # {name}
      {Icon ? <Icon className={classes.icon} /> : null}
    </ButtonBase>
  );
};

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.elementType,
  iconColor: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    borderRadius: '4px',
    border: 'black 1px solid',
    fontSize: '18px',
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '18px',
    },
    padding: '4px',
    marginBottom: '8px',
    marginRight: '20px',
  },
  icon: ({ iconColor }) => ({
    marginLeft: theme.spacing(0.5),
    fontSize: '18px',
    lineHeight: '20px',
    opacity: '20%',
    'button:hover > &': {
      opacity: '100%',
      color: iconColor ? iconColor : theme.palette.common.black,
    },
  }),
}));

export default Tag;
