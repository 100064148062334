import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { dotify } from '../../utils/functions';

import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import Tag from '../../components/Tag';
import UploadSuccessModal from '../../components/PostUploads/UploadSuccessModal';

import {
  Typography,
  DialogTitle,
  IconButton,
  Box,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import UploadForm from './UploadForm';

const UploadButton = ({ size }) => {
  const classes = useStyles();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUploadSuccessModalOpen, setIsUploadSuccessModalOpen] = useState(
    false
  );
  const [currentUploadData, setCurrentUploadData] = useState(null);

  const handleUploadModalClose = () => setIsUploadModalOpen(false);
  const handleUploadModalOpen = () => setIsUploadModalOpen(true);

  const handleUploadSuccessModalClose = () =>
    setIsUploadSuccessModalOpen(false);
  const handleUploadSuccessModalOpen = () => setIsUploadSuccessModalOpen(true);

  const handleUploadModalSuccess = uploadedData => {
    setCurrentUploadData(uploadedData);

    handleUploadModalClose();
    handleUploadSuccessModalOpen();
  };

  const getImageSrcFromUpload = upload => {
    if (!upload) return null;

    if (upload.cover) {
      return upload.cover.url;
    } else if (Array.isArray(upload.attachments)) {
      const img = upload.attachments.find(doc => doc.mime.includes('image'));

      return img ? img.url : null;
    } else {
      return null;
    }
  };

  const getPdfSrcFromUpload = upload => {
    if (!upload || !Array.isArray(upload.attachments)) {
      return null;
    }

    const pdfs = upload.attachments.reduce((acc, curr) => {
      if (curr.ext === '.pdf') {
        return [...acc, curr];
      } else {
        return acc;
      }
    }, []);

    return pdfs.length > 0 ? pdfs[0].url : null;
  };

  return (
    <>
      <Button size={size} onClick={handleUploadModalOpen}>
        {'Upload'}
      </Button>

      <Dialog
        open={isUploadModalOpen}
        onClose={handleUploadModalClose}
        maxWidth="md"
        fullWidth
        scroll="body"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.dialogTitleText}>
              {'Upload'}
            </Typography>

            <IconButton onClick={handleUploadModalClose}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <UploadForm
            handleUploadModalClose={handleUploadModalClose}
            handleUploadModalSuccess={handleUploadModalSuccess}
          />
        </DialogContent>
      </Dialog>

      {currentUploadData ? (
        <Dialog
          open={isUploadSuccessModalOpen}
          onClose={handleUploadSuccessModalClose}
          maxWidth="md"
          fullWidth
          scroll="body"
          classes={{
            paper: classes.dialog,
          }}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Typography className={classes.dialogTitleText}>
              {'Success! Your post has been uploaded'}
            </Typography>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <UploadSuccessModal
              onClose={handleUploadSuccessModalClose}
              redirectToSlug={currentUploadData.slug}
              imgSrc={getImageSrcFromUpload(currentUploadData)}
              pdfSrc={getPdfSrcFromUpload(currentUploadData)}
              modalTitle={currentUploadData.title}
              contents={
                currentUploadData.content ? (
                  <>
                    <Box mt={4}>
                      <Typography className={classes.label}>
                        {'Description'}
                      </Typography>
                      <Typography className={classes.text}>
                        {dotify(currentUploadData.content, 300)}
                      </Typography>
                    </Box>

                    <Box mt={4}>
                      <Typography className={classes.label}>
                        {'Share with'}
                      </Typography>
                      <Typography className={classes.text}>
                        {currentUploadData.shareWith === 'me'
                          ? 'Me'
                          : currentUploadData.shareWith === 'my_group'
                          ? 'Me, My Cohort'
                          : 'Me, My Cohort, Everyone'}
                      </Typography>
                    </Box>

                    {currentUploadData.tags &&
                    currentUploadData.tags.length > 0 ? (
                      <Box mt={6}>
                        <Typography className={classes.label} gutterBottom>
                          {'Tags'}
                        </Typography>

                        {currentUploadData.tags.split(';').map(tag => (
                          <Tag key={`tag_${tag}`} name={tag} />
                        ))}
                      </Box>
                    ) : null}
                  </>
                ) : null
              }
            />
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
};

UploadButton.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
};

UploadButton.defaultProps = {
  size: 'default',
};

const useStyles = makeStyles(theme => ({
  dialog: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogTitleText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  label: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    fontSize: '16px',
  },
}));

export default UploadButton;
