import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';

const Checkbox = ({ className, name, label, checked, onChange, ...rest }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={clsx(classes.root, className)}
      label={label}
      control={
        <MuiCheckbox
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
          {...rest}
          className={classes.input}
          disableRipple
          color="default"
          icon={<span className={classes.icon} />}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
        />
      }
    />
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  className: '',
};

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '16px',
  },
  input: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    position: 'relative',
    width: '23px',
    height: '23px',
    border: '2px solid',
    borderColor: theme.palette.common.black,
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    'input:disabled ~ &': {
      opacity: '25%',
    },
  },
  checkedIcon: {
    '&:before': {
      content: '""',
      display: 'block',
      width: '5px',
      height: '10px',
      border: 'solid black',
      borderWidth: '0 1px 1px 0',
      transform: 'rotate(45deg)',
      position: 'absolute',
      left: '7px',
      top: '3px',
    },
  },
}));

export default Checkbox;
