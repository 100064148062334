import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { SEARCH_RESULTS_LIMIT } from '../../config/request.config';
import { getSearchResults, setStart } from '../../store/actions/searchActions';

import { Box } from '@material-ui/core';

import SearchActions from './SearchActions';
import SearchResults from './SearchResults';
import SearchCompletions from './SearchCompletions';
import RecentPosts from './RecentPosts';

const INITIAL_START = 0;

const Search = ({
  currentUser,
  getSearchResults,
  setStart,
  searchResults,
  hasMoreResults,
  start,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { searchTerm: externalSearchTerm } = useParams();

  const memberType = useMemo(() => get(currentUser, 'role.type'), [
    currentUser,
  ]);

  const [searchTerm, setSearchTerm] = useState(externalSearchTerm || '');
  const [didSearch, setDidSearch] = useState(false);

  useEffect(() => {
    if (externalSearchTerm) {
      handleSearch(externalSearchTerm);
    }
    // eslint-disable-next-line
  }, []);

  const handleSearchTermChange = e => {
    setSearchTerm(e.target.value);
    setDidSearch(false);
  };

  const handleBack = useCallback(() => {
    if (!currentUser || !memberType) return;
    history.push('/');
  }, [history, currentUser, memberType]);

  const handleGetSearchResults = useCallback(
    async (start, searchTerm) => {
      setStart(start);
      await getSearchResults({ start, searchTerm });
    },
    [getSearchResults, setStart]
  );

  const handleSearch = useCallback(
    async targetTerm => {
      if (searchTerm !== targetTerm) {
        setSearchTerm(targetTerm);
      }

      await handleGetSearchResults(INITIAL_START, targetTerm);

      setDidSearch(true);
    },
    [handleGetSearchResults, searchTerm]
  );

  return (
    <main className={classes.root}>
      <Helmet>
        <title>{'Search'}</title>
      </Helmet>

      {/* Search actions */}
      <SearchActions
        searchTerm={searchTerm}
        handleSearchTermChange={handleSearchTermChange}
        handleSearchClose={handleBack}
        handleSearch={handleSearch}
      />

      {/* Search content */}
      <Box className={classes.content}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={logBoundaryError}
        >
          {didSearch ? (
            <>
              {/* Search results */}
              <SearchResults
                results={searchResults}
                hasMoreResults={hasMoreResults}
                start={start}
                loadMoreResults={() =>
                  handleGetSearchResults(
                    start + SEARCH_RESULTS_LIMIT,
                    searchTerm
                  )
                }
              />
            </>
          ) : (
            <>
              {/* Search completions */}
              <SearchCompletions
                searchTerm={searchTerm}
                handleSearch={handleSearch}
              />

              {/* Recent posts */}
              <RecentPosts />
            </>
          )}
        </ErrorBoundary>
      </Box>
    </main>
  );
};

Search.propTypes = {
  currentUser: PropTypes.object.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  start: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
  },
  content: {
    backgroundColor: '#F7F8F9',
    padding: `${theme.spacing(4)}px 0`,
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  searchResults: state.search.results,
  hasMoreResults: state.search.hasMore,
  start: state.search.start,
});

export default connect(mapStateToProps, { getSearchResults, setStart })(Search);
