import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './utils/ErrorFallback';
import { logBoundaryError } from '../utils/functions';

import { teacherTabs } from '../config/tabs.config';
import { HTLA_POST_SELECT_VALUES } from '../constants';

import { getPostsByCategory, setStart } from '../store/actions/postActions';

import { Typography, Box } from '@material-ui/core';

import TabNavigation from './tabNavigation/index';
import Header from './Header';
import CardsGrid from './CardsGrid';
import Select from './inputs/Select';
import { useUserType } from '../hooks/useUserType';

const TeacherDashboardComponent = ({ name, ...props }) => {
  const { currentUser, posts, setStart, getPostsByCategory } = props;
  const classes = useStyles(props);
  const userType = useUserType();

  const [postsType, setPostsType] = useState(HTLA_POST_SELECT_VALUES[0].value);
  const handlePostsTypeChange = e => setPostsType(e.target.value);

  const loadData = start =>
    getPostsByCategory({
      start,
      category: name,
      memberType: userType,
      type: postsType,
    });

  useEffect(() => {
    loadData(0);
    setStart(0);
    // eslint-disable-next-line
  }, [postsType]);

  return (
    <div>
      <Helmet>
        <title>{'Home'}</title>
      </Helmet>

      <Header user={currentUser} isWelcome />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <TabNavigation activeTab={name} memberType={userType}>
          <div className={classes.container}>
            <Box
              pb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <div>
                <Typography className={classes.title}>
                  {teacherTabs[name].name}
                </Typography>
                <Typography className={classes.subtitle}>
                  {teacherTabs[name].subtitle}
                </Typography>
              </div>

              <Select
                name="postsTypeSelect"
                value={postsType}
                onChange={handlePostsTypeChange}
                options={HTLA_POST_SELECT_VALUES}
              />
            </Box>

            <CardsGrid data={posts} loadMore={loadData} />
          </div>
        </TabNavigation>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    lineHeight: '43px',
    fontWeight: 'bold',
    marginBottom: '8px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginBottom: '4px',
      lineHeight: '26px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '15px',
    },
  },
  container: {
    paddingTop: '30px',
    paddingBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '1088px',
    },
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  posts: state.posts.posts,
});

export default connect(mapStateToProps, { getPostsByCategory, setStart })(
  TeacherDashboardComponent
);
