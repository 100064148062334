import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SizeMe } from 'react-sizeme';

import { Box, Button, Typography } from '@material-ui/core';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({ url, disableControls }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div>
      <SizeMe>
        {({ size }) => (
          <Document file={{ url }} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              width={size.width ? size.width : undefined}
            />
          </Document>
        )}
      </SizeMe>

      {!disableControls ? (
        <>
          <Typography align="center">
            Page {pageNumber} of {numPages}
          </Typography>

          <Box display="flex" justifyContent="center">
            <Button
              disabled={pageNumber === 1}
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            >
              Previous
            </Button>
            <Button
              disabled={pageNumber === numPages}
              onClick={() => {
                if (pageNumber < numPages) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            >
              Next
            </Button>
          </Box>
        </>
      ) : null}
    </div>
  );
};

PDFViewer.propTypes = {
  url: PropTypes.string.isRequired,
  disableControls: PropTypes.bool,
};

PDFViewer.defaultProps = {
  disableControls: false,
};

export default PDFViewer;
