import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarContent } from 'notistack';

import { grey } from '../../config/colors.config';

import { dotify } from '../../utils/functions';

import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Button from '../Button';
import PDFViewer from '../utils/PDFViewer';

const UploadSuccessToast = forwardRef(
  (
    {
      onClose,
      redirectToSlug,
      imgSrc,
      pdfSrc,
      uploadTitle,
      activityName,
      history,
    },
    ref
  ) => {
    const classes = useStyles();

    const handleAction = () => {
      history.push({
        pathname: `/posts/${redirectToSlug}`,
        state: { isUpload: true, imgSrc, activityName },
      });
    };

    return (
      <SnackbarContent ref={ref}>
        <div className={classes.root}>
          <IconButton onClick={onClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>

          <Box display="flex">
            {imgSrc ? (
              <img
                alt={'Uploaded content preview'}
                src={imgSrc}
                className={classes.preview}
              />
            ) : pdfSrc ? (
              <div className={classes.pdfPreview}>
                <PDFViewer url={pdfSrc} disableControls />
              </div>
            ) : null}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              justifyContent="center"
            >
              <Box mb={4}>
                <Typography className={classes.title}>Success!</Typography>

                <Typography className={classes.description}>
                  {`${dotify(uploadTitle, 100)} has been uploaded`}
                </Typography>
              </Box>

              <Box display="flex">
                <Button onClick={handleAction} fullWidth>
                  {'Show me'}
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </SnackbarContent>
    );
  }
);

UploadSuccessToast.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectToSlug: PropTypes.string,
  imgSrc: PropTypes.string,
  pdfSrc: PropTypes.string,
  uploadTitle: PropTypes.string,
  activityName: PropTypes.string,
  history: PropTypes.object.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '6px',
    border: `1px solid ${grey}`,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(6),
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  preview: {
    objectFit: 'cover',
    marginRight: theme.spacing(4),
    width: '50px',
    [theme.breakpoints.up('md')]: {
      width: '150px',
    },
  },
  pdfPreview: {
    marginRight: theme.spacing(4),
    width: '50px',
    [theme.breakpoints.up('md')]: {
      width: '150px',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '24px',
  },
  description: {
    fontSize: '16px',
  },
}));

export default UploadSuccessToast;
