import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core';

import Avatar from '../../components/Avatar';

const ProfileHeader = ({ avatarSrc, initials, fullName, description }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" py={2} className={classes.root}>
      <Box pl={0.5} pr={0.5}>
        <Avatar
          size="big"
          imgSrc={avatarSrc ? avatarSrc : null}
          initials={initials}
        />
      </Box>

      <Box flexGrow={1} className={classes.profileInfo}>
        <Typography className={classes.fullName}>{fullName}</Typography>

        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

ProfileHeader.propTypes = {
  avatarSrc: PropTypes.string,
  initials: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {},
  profileInfo: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6),
    },
  },
  fullName: {
    fontSize: '32px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      textAlign: 'center',
    },
    fontWeight: theme.typography.fontWeightBold,
  },
  description: {
    marginTop: theme.spacing(0.5),
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      textAlign: 'center',
    },
  },
}));

export default ProfileHeader;
