import {
  GET_CHAT_CONTENTS,
  GET_CHAT_CONTENTS_SUCCESS,
  GET_CHAT_CONTENTS_FAILURE,
  RESET_QUERY,
  ADD_MESSAGE,
} from '../actionTypes/chatActionTypes';

const initialState = {
  results: [],
  loading: false,
  error: null,
  start: 0,
  hasMore: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CHAT_CONTENTS:
      return {
        ...state,
        loading: true,
        hasMore: false,
      };

    case GET_CHAT_CONTENTS_SUCCESS:
      const { reset, results, start, hasMore } = action.payload;

      return {
        ...state,
        loading: false,
        results: reset
          ? results.reverse()
          : [...results.reverse(), ...state.results],
        start,
        hasMore,
      };

    case GET_CHAT_CONTENTS_FAILURE:
      return {
        ...state,
        loading: false,
        start: 0,
        hasMore: false,
        error: action.payload,
      };

    case RESET_QUERY:
      return {
        ...state,
        start: 0,
        hasMore: false,
        error: null,
      };

    case ADD_MESSAGE:
      return {
        ...state,
        results: [
          ...state.results,
          {
            ...action.payload,
            chat_type: 'MESSAGE',
          },
        ],
      };

    default:
      return state;
  }
}
