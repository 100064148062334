import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { times } from 'lodash';

import { darkGrey } from '../../../config/colors.config';

import { Grid } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

const UploadButtonGrid = ({ number, onClick }) => {
  const classes = useStyles();

  const buttons = useMemo(() => {
    const buttons = [];

    times(number, () => {
      buttons.push(
        <button onClick={onClick} className={classes.button}>
          <Add fontSize="large" />
          <span>{'Upload'}</span>
        </button>
      );
    });

    return buttons;
  }, [classes, number, onClick]);

  const smCols = useMemo(() => {
    switch (number) {
      case 1:
        return 12;
      default:
        return 6;
    }
  }, [number]);

  const lgAndUpCols = useMemo(() => {
    switch (number) {
      case 1:
        return 12;
      case 2:
        return 6;
      case 3:
        return 4;
      default:
        return 3;
    }
  }, [number]);

  return (
    <Grid container spacing={3}>
      {buttons.map((button, index) => (
        <Grid
          key={`upload_button_${index}`}
          item
          xs={12}
          sm={smCols}
          lg={lgAndUpCols}
        >
          {button}
        </Grid>
      ))}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    height: '250px',
    width: '100%',
    backgroundColor: 'white',
    border: `1px  dashed ${darkGrey}`,
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
}));

UploadButtonGrid.propTypes = {
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UploadButtonGrid;
