import { captureException } from '@sentry/react';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import {
  GET_THINGS_TO_BRING,
  GET_DAY_ACTIVITIES,
  GET_SESSIONS,
  SET_CURRENT_UPLOAD,
} from '../actionTypes/studentActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getThingsToBring = () => async dispatch => {
  try {
    const res = await ApiClient.get(`/ypla-phase-1`);

    dispatch({
      type: GET_THINGS_TO_BRING,
      payload: res.data.things_to_bring,
    });

    return res.data.things_to_bring;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const checkChecklistItem = itemId => async dispatch => {
  try {
    await ApiClient.post(`/ypla-phase-1/checklist/${itemId}/check`);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const uncheckChecklistItem = itemId => async dispatch => {
  try {
    await ApiClient.post(`/ypla-phase-1/checklist/${itemId}/uncheck`);
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const getDayActivities = () => async dispatch => {
  try {
    const res = await ApiClient.get(`/ypla-phase-2`);

    dispatch({
      type: GET_DAY_ACTIVITIES,
      payload: res.data.ypla_day_activities,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};
export const getSessions = () => async dispatch => {
  try {
    const res = await ApiClient.get(`/ypla-phase-3`);

    dispatch({
      type: GET_SESSIONS,
      payload: res.data.ypla_sessions,
    });
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const uploadSecondPhaseStuff = uploadData => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(uploadData.data));

    if (Array.isArray(uploadData.attachments)) {
      uploadData.attachments.forEach(attachment => {
        formData.append('files.attachments', attachment);
      });
    }

    const res = await ApiClient.post(`/ypla-phase-2/upload`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });

    dispatch({
      type: SET_CURRENT_UPLOAD,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};

export const uploadThirdPhaseStuff = uploadData => async dispatch => {
  try {
    const formData = new FormData();
    formData.append('data', JSON.stringify(uploadData.data));

    if (Array.isArray(uploadData.attachments)) {
      uploadData.attachments.forEach(attachment => {
        formData.append('files.attachments', attachment);
      });
    }

    const res = await ApiClient.post(`/ypla-phase-3/upload`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });

    dispatch({
      type: SET_CURRENT_UPLOAD,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      })
    );

    captureException(error);
  }
};
