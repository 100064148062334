import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/utils/ErrorFallback';
import { logBoundaryError } from '../utils/functions';

import welcomeImage from '../assets/img/welcomeImage.jpg';
import logo from '../assets/img/blackLogo.svg';

import { askForPasswordReset } from '../store/actions/userActions';

import { Grid, Hidden } from '@material-ui/core';

import AuthForm from '../components/AuthForm';

class ForgotPasswordPage extends React.Component {
  schema = Yup.object().shape({
    email: Yup.string().email().required().label('Email address'),
  });
  initialValues = {
    email: '',
  };

  componentDidMount() {
    const { currentUser } = this.props;

    if (currentUser) {
      this.handleRedirection();
    }
  }

  onSubmit = async (values, { setSubmitting }) => {
    const { askForPasswordReset } = this.props;

    await askForPasswordReset({ email: values.email });
    setSubmitting(false);
  };

  handleRedirection = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container component="main" className={classes.container}>
        <Helmet>
          <title>{'Forgot password'}</title>
        </Helmet>

        <Grid item md={5} className={classes.formContainer}>
          <img alt="logo" src={logo} className={classes.logo} />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={logBoundaryError}
          >
            <AuthForm
              validationSchema={this.schema}
              initialValues={this.initialValues}
              onSubmit={this.onSubmit}
              title="Forget password"
              type="forgot password"
            />
          </ErrorBoundary>
        </Grid>
        <Hidden smDown>
          <Grid item md={7} className={classes.image} />
        </Hidden>
      </Grid>
    );
  }
}

const styles = {
  container: {
    minHeight: '100vh',
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 64px',
  },
  image: {
    backgroundImage: `url(${welcomeImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '100% 0',
    width: '100%',
  },
  logo: {
    marginBottom: '50px',
  },
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  userProgress: state.user.userProgress,
});

export default connect(mapStateToProps, {
  askForPasswordReset,
})(withStyles(styles)(ForgotPasswordPage));
