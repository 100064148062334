import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { dotify } from '../../utils/functions';

import {
  useMediaQuery,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';

const HighlightItem = ({
  variant,
  postUrl,
  postTitle,
  postAuthor,
  highlightText,
  highlightColor,
  highlightTextColor,
  date,
}) => {
  const theme = useTheme();
  const classes = useStyles({ variant, highlightColor, highlightTextColor });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const isGrid = variant === 'grid';

  if (!highlightText || !postTitle || !postAuthor || !postUrl) {
    return null;
  }

  return (
    <Link to={`/posts/${postUrl}`} className={classes.root}>
      <Card className={classes.card}>
        <CardActionArea className={classes.cardActionArea}>
          <CardContent className={classes.cardContent}>
            <Box
              display="flex"
              justifyContent={
                isMobile || isGrid ? 'flex-start' : 'space-between'
              }
            >
              <Typography className={classes.postInfo}>
                {'From '}
                <b>
                  {dotify(postTitle, isMobile ? 30 : isLargeScreen ? 100 : 40)}
                </b>
                {` by ${postAuthor}`}
              </Typography>

              {!isMobile && !isGrid ? (
                <Typography className={classes.highlightInfo}>
                  {`Highlighted on ${moment(date).format('Do MMMM YYYY')}`}
                </Typography>
              ) : null}
            </Box>

            <Box mt={2}>
              <Typography className={classes.highlightText}>
                {highlightText}
              </Typography>
            </Box>

            {isMobile || isGrid ? (
              <Typography className={classes.highlightInfo}>
                {`Highlighted on ${moment(date).format('Do MMMM YYYY')}`}
              </Typography>
            ) : null}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

HighlightItem.propTypes = {
  variant: PropTypes.oneOf(['list', 'grid']).isRequired,
  postUrl: PropTypes.string.isRequired,
  postTitle: PropTypes.string.isRequired,
  postAuthor: PropTypes.string.isRequired,
  highlightText: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  highlightTextColor: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textDecoration: 'none',
  },
  card: {
    height: '100%',
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  postInfo: ({ variant }) => ({
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    ...(variant === 'grid' ? { fontSize: '18px' } : null),
  }),
  highlightInfo: ({ variant }) => ({
    fontSize: '15px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    ...(variant === 'grid' ? { marginTop: theme.spacing(2) } : null),
  }),
  highlightText: ({ variant, highlightColor, highlightTextColor }) => ({
    padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px`,
    backgroundColor: highlightColor ? highlightColor : '	#FFFF00',
    color: highlightTextColor ? highlightTextColor : '#fff',
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    ...(variant === 'grid' ? { fontSize: '16px' } : null),
  }),
}));

export default HighlightItem;
