import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress } from '@material-ui/core';

const Loader = () => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.spinner} />
    </div>
  );
};

const styles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.info.light,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    color: theme.palette.common.white,
  },
}));

export default Loader;
