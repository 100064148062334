import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { studentTabs } from '../../config/tabs.config';

import { getUser } from '../../store/actions/userActions';
import { getPostsByCategory, setStart } from '../../store/actions/postActions';
import {
  getThingsToBring,
  checkChecklistItem,
  uncheckChecklistItem,
} from '../../store/actions/studentActions';
import { getGroupMembers } from '../../store/actions/groupActions';

import { Typography, Hidden, useMediaQuery } from '@material-ui/core';
import { Group } from '@material-ui/icons';

import Header from '../../components/Header';
import TabNavigation from '../../components/tabNavigation/index';
import CardsGrid from '../../components/CardsGrid';
import Checkbox from '../../components/inputs/Checkbox';
import AvatarWithTitle from '../../components/AvatarWithTitle';

const StudentDashboard = props => {
  const {
    currentUser,
    posts,
    groupMembers,
    setStart,
    getPostsByCategory,
    getThingsToBring,
    checkChecklistItem,
    uncheckChecklistItem,
    getGroupMembers,
    getUser,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [checkboxes, setCheckboxes] = useState({});
  const [checklistClicked, setChecklistClicked] = useState(false);
  const [groupClicked, setGroupClicked] = useState(false);

  const loadData = start =>
    getPostsByCategory({
      start,
      category: 'phase1',
      memberType: 'ypla',
      type: 'all',
    });

  useEffect(() => {
    (async () => {
      loadData(0);
      setStart(0);

      const currentUser = await getUser();
      await getGroupMembers();
      const thingsToBring = await getThingsToBring();

      const userTickedItems =
        currentUser &&
        currentUser.ticked_things_to_bring &&
        currentUser.ticked_things_to_bring.length > 0
          ? currentUser.ticked_things_to_bring.split(';')
          : [];

      let initialCheckboxes = {};
      thingsToBring.forEach(thing => {
        initialCheckboxes[thing.id] = {
          ...thing,
          checked: userTickedItems.includes(thing.id.toString()),
          id: thing.id.toString(),
        };
      });

      setCheckboxes(initialCheckboxes);
    })();
    // eslint-disable-next-line
  }, [setStart]);

  const onCheckboxClick = e => {
    const checkbox = checkboxes[e.target.id];

    if (checkbox.checked) {
      uncheckChecklistItem(checkbox.id);
    } else {
      checkChecklistItem(checkbox.id);
    }

    setCheckboxes({
      ...checkboxes,
      [e.target.id]: { ...checkbox, checked: !checkbox.checked },
    });
  };

  const onGroupClick = () => {
    setGroupClicked(!groupClicked);
    if (checklistClicked) {
      setChecklistClicked(false);
    }
  };

  const onChecklistClick = () => {
    setChecklistClicked(!checklistClicked);
    if (groupClicked) {
      setGroupClicked(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{'Home'}</title>
      </Helmet>

      <Header user={currentUser} isWelcome />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <TabNavigation activeTab={'phase1'} memberType="ypla">
          <div className={classes.container}>
            <Typography className={classes.title}>
              {studentTabs.phase1.title}
            </Typography>
            <Typography className={classes.subtitle}>
              {studentTabs.phase1.subtitle}
            </Typography>
            <div className={classes.mainDataContainer}>
              <div className={classes.buttonsContainer}>
                <Hidden mdUp>
                  <div
                    className={`${classes.mobileDataContainer} ${classes.checkboxTitleContainer}`}
                    onClick={onChecklistClick}
                  >
                    <Checkbox
                      label="Things to bring"
                      name="Things to bring"
                      checked={true}
                    />
                  </div>
                  <div
                    className={`${classes.mobileDataContainer} ${classes.groupTitleContainer}`}
                    onClick={onGroupClick}
                  >
                    <Group fontSize={'small'} />
                    <Typography className={classes.number}>
                      {groupMembers.length}
                    </Typography>
                    <Typography className={classes.mobileFont}>
                      Your Group
                    </Typography>
                  </div>
                </Hidden>
              </div>
              {(!smAndDown || checklistClicked) && (
                <div
                  className={`${classes.dataContainer} ${classes.leftContainer}`}
                >
                  <Typography>Things to bring</Typography>
                  <div className={classes.checklist}>
                    {Object.values(checkboxes).map(thing => (
                      <Checkbox
                        label={thing.label}
                        name={thing.id}
                        checked={thing.checked}
                        onChange={e => onCheckboxClick(e)}
                        key={thing.id}
                      />
                    ))}
                  </div>
                </div>
              )}
              {(!smAndDown || groupClicked) && (
                <div
                  className={`${classes.dataContainer} ${classes.rightContainer}`}
                >
                  <div
                    className={`${classes.groupTitleContainer} ${classes.bottomMargin}`}
                  >
                    <Group fontSize={'large'} />
                    <Typography className={classes.groupTitle}>
                      Your Group
                    </Typography>
                  </div>
                  {groupMembers.map(user => (
                    <div className={classes.groupMate} key={user.id}>
                      <AvatarWithTitle
                        size="small"
                        initials={`${
                          _.isString(user.firstName)
                            ? user.firstName.charAt(0)
                            : ''
                        }${
                          _.isString(user.lastName)
                            ? user.lastName.charAt(0)
                            : ''
                        }`}
                        title={`${user.firstName} ${user.lastName[0]}`}
                        imgSrc={user.avatar && user.avatar.url}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <CardsGrid data={posts} loadMore={loadData} />
          </div>
        </TabNavigation>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    lineHeight: '43px',
    fontWeight: 'bold',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
      fontSize: '20px',
      marginBottom: '4px',
      lineHeight: '26px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
    },
  },
  container: {
    paddingTop: '30px',
    paddingBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '742px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1090px',
    },
  },
  dataContainer: {
    border: '0.6px solid black',
    borderRadius: '3.6px',
    padding: '24px 15px',
    [theme.breakpoints.up('md')]: {
      height: '55vh',
      overflowY: 'auto',
    },
  },
  mobileDataContainer: {
    border: '0.6px solid black',
    borderRadius: '3.6px',
    padding: '8px',
    width: '48%',
    cursor: 'pointer',
    '& label': {
      margin: 0,
    },
    '& span': {
      fontSize: '14px',
    },
  },
  checklist: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    marginLeft: '20px',
  },
  checklistItem: {
    paddingBottom: '20px',
  },
  groupMate: {
    paddingBottom: '12px',
  },
  mainDataContainer: {
    margin: '45px 0',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      margin: '30px 10px',
      flexDirection: 'column',
    },
  },
  groupTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bottomMargin: {
    marginBottom: '26px',
  },
  groupTitle: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '21px',
    },
  },
  rightContainer: {
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
  },
  leftContainer: {
    [theme.breakpoints.up('md')]: {
      width: '68%',
    },
  },
  number: {
    fontWeight: 'bold',
    margin: '0 7px',
  },
  mobileFont: {
    fontSize: '14px',
  },
  checkboxTitleContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  posts: state.posts.posts,
  thingsToBring: state.student.thingsToBring,
  groupMembers: state.group.groupMembers,
});

export default connect(mapStateToProps, {
  getUser,
  getPostsByCategory,
  setStart,
  getThingsToBring,
  checkChecklistItem,
  uncheckChecklistItem,
  getGroupMembers,
})(StudentDashboard);
