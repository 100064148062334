import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({
  currentUser,
  component: Component,
  allowedFor,
  path,
}) => {
  const isAuthenticated = React.useMemo(() => Boolean(currentUser), [
    currentUser,
  ]);
  const hasAccess = React.useMemo(() => {
    if (!currentUser || !currentUser.role || !currentUser.role.type) {
      return false;
    }

    if (!allowedFor) {
      return true;
    } else {
      return allowedFor.includes(currentUser.role.type);
    }
  }, [currentUser, allowedFor]);

  return isAuthenticated ? (
    hasAccess ? (
      <Route exact path={path} component={Component} />
    ) : (
      <Redirect to={{ pathname: '/no-access' }} />
    )
  ) : (
    <Redirect to={{ pathname: '/sign-in' }} />
  );
};

ProtectedRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  component: PropTypes.elementType,
  allowedFor: PropTypes.arrayOf(
    PropTypes.oneOf(['htla_member', 'ypla_member', 'stla_member'])
  ).isRequired,
};

ProtectedRoute.defaultProps = {
  exact: false,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
});

export default connect(mapStateToProps)(ProtectedRoute);
