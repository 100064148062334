import {
  GET_THINGS_TO_BRING,
  GET_SESSIONS,
  GET_DAY_ACTIVITIES,
  SET_CURRENT_UPLOAD,
} from '../actionTypes/studentActionTypes';

const initialState = {
  thingsToBring: [],
  sessions: [],
  dayActivities: [],
  currentUpload: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_THINGS_TO_BRING:
      return {
        ...state,
        thingsToBring: action.payload,
      };

    case GET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      };

    case GET_DAY_ACTIVITIES:
      return {
        ...state,
        dayActivities: action.payload,
      };

    case SET_CURRENT_UPLOAD:
      return {
        ...state,
        currentUpload: action.payload,
      };

    default:
      return state;
  }
}
