import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import shortid from 'shortid';

import { getContentTypeFromEntity } from '../../utils/functions';
import { getMyColumba } from '../../store/actions/myColumbaActions';

import {
  useMediaQuery,
  CircularProgress,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';

import ContentItem from '../../components/ContentItem';

const Content = ({ filter, view, getMyColumba, results, resultsLoading }) => {
  const theme = useTheme();
  const smAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getMyColumba({ filter });
  }, [getMyColumba, filter]);

  if (resultsLoading) {
    return (
      <Box py={4} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box py={4} minHeight="80vh">
      {Array.isArray(results) && results.length > 0 ? (
        <Grid container spacing={3} justify={smAndDown ? 'center' : undefined}>
          {results.map(result => (
            <ContentItem
              key={`mycolumba_result_${shortid.generate()}`}
              type={getContentTypeFromEntity(result)}
              variant={view}
              data={result}
            />
          ))}
        </Grid>
      ) : (
        <Typography align="center">{'No results.'}</Typography>
      )}
    </Box>
  );
};

Content.propTypes = {
  filter: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  results: state.myColumba.results,
  resultsLoading: state.myColumba.loading,
});

export default connect(mapStateToProps, {
  getMyColumba,
})(Content);
