import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';

const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
});

const MarkdownParser = ({ content }) => {
  const classes = useStyles();

  return (
    <ReactMarkdown
      source={content}
      escapeHtml={false}
      astPlugins={[parseHtml]}
      className={classes.content}
      sourcePos
    />
  );
};

MarkdownParser.propTypes = {
  content: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  content: {
    fontSize: '20px',
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& strong': {
      fontSize: '32px',
      lineHeight: '42px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
    '& blockquote': {
      borderLeft: '4px solid rgba(0, 0, 0, 0.1)',
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      fontStyle: 'italic',
    },
    '& img': {
      minWidth: '40%',
      maxWidth: '70%',
      display: 'block',
      margin: '0 auto',
    },
    '& iframe': {
      minWidth: '40%',
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto',
    },
  },
}));

export default MarkdownParser;
