import React from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import { closeSnackbar } from '../../store/actions/snackbarActions';

const Notifier = ({ notification, closeSnackbar }) => {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (notification) {
      const { message, options } = notification;

      enqueueSnackbar(message, {
        ...options,
        autoHideDuration: 7000,
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
        onClose: () => closeSnackbar(),
      });
    }
  }, [notification, enqueueSnackbar, closeSnackbar]);

  return null;
};

const mapStateToProps = state => ({
  notification: state.snackbar.notification,
});

export default connect(mapStateToProps, {
  closeSnackbar,
})(Notifier);
