import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Picker } from 'emoji-mart';
import { Smile, Paperclip } from 'react-feather';
import OutsideClickHandler from 'react-outside-click-handler';

import { MAX_UPLOAD_SIZE } from '../../config/uploads.config';
import { sendChatMessage } from '../../store/actions/chatActions';

import {
  useMediaQuery,
  Box,
  InputBase,
  InputAdornment,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

const ChatInput = ({
  sendChatMessage,
  academy,
  chatLoading,
  scrollToBottom,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const attachmentsRef = useRef(null);

  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleMessageChange = e => {
    setMessage(e.target.value);
  };

  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

  const addEmoji = emoji => {
    if (message.length > 0) {
      setMessage(message.concat(' ').concat(emoji.native));
    } else {
      setMessage(message.concat(emoji.native));
    }

    setShowEmojiPicker(false);
  };

  const handleAttachmentsSelect = e => {
    const { files } = e.target;
    if (!files) return;

    const accepted = Array.from(files).filter(
      file => file.size <= MAX_UPLOAD_SIZE
    );

    setAttachments([...attachments, ...accepted]);
  };

  const sendMessage = async e => {
    e.preventDefault();

    setIsSubmitting(true);
    await sendChatMessage({ message, attachments });
    setIsSubmitting(false);

    setMessage('');
    setAttachments([]);
    scrollToBottom();
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={sendMessage} className={classes.form}>
        <InputBase
          className={classes.input}
          name="message"
          type="text"
          value={message}
          onChange={handleMessageChange}
          placeholder={
            academy === 'htla' ? 'Message Cohort...' : 'Message Group...'
          }
          fullWidth
          required
          disabled={isSubmitting}
          endAdornment={
            <InputAdornment position="end" className={classes.inputActions}>
              <ButtonBase
                onClick={toggleEmojiPicker}
                className={classes.emojiButton}
                disableRipple
                disableTouchRipple
              >
                <Smile size={isMobile ? 20 : 24} />
              </ButtonBase>

              <input
                type="file"
                multiple
                style={{
                  display: 'none',
                }}
                ref={attachmentsRef}
                onChange={handleAttachmentsSelect}
              />
              <ButtonBase
                onClick={() => {
                  if (attachmentsRef && attachmentsRef.current) {
                    attachmentsRef.current.click();
                  }
                }}
                className={classes.attachmentsButton}
                disableRipple
                disableTouchRipple
              >
                <Paperclip size={isMobile ? 20 : 24} />
                {attachments.length > 0 ? (
                  <Typography className={classes.attachmentsNumber}>
                    {` (${attachments.length})`}
                  </Typography>
                ) : null}
              </ButtonBase>

              <ButtonBase
                type="submit"
                disabled={isSubmitting || chatLoading}
                className={classes.sendButton}
              >
                <SendIcon />
              </ButtonBase>
            </InputAdornment>
          }
        />

        <OutsideClickHandler onOutsideClick={() => setShowEmojiPicker(false)}>
          <Picker
            title="Pick your emoji"
            emoji="point_up"
            onSelect={addEmoji}
            native
            style={{
              display: showEmojiPicker ? 'block' : 'none',
              position: 'absolute',
              right: isMobile ? 15 : 25,
              top: -450,
            }}
          />
        </OutsideClickHandler>
      </form>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: '100px',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      height: '66px',
    },
  },
  form: {
    position: 'relative',
  },
  input: {
    border: '1px solid #000000',
    borderRadius: '8px',
    padding: '16px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      borderTop: '1px solid rgba(0, 0, 0, 0.25)',
      borderLeft: 0,
      borderRight: 0,
      borderBottom: 0,
      fontSize: '12px',
    },
  },
  inputActions: {},
  emojiButton: {
    padding: theme.spacing(0.5),
    opacity: '40%',
    '&:hover': {
      opacity: '100%',
    },
  },
  attachmentsButton: {
    padding: theme.spacing(0.5),
    opacity: '40%',
    '&:hover': {
      opacity: '100%',
    },
    marginLeft: theme.spacing(1.5),
  },
  attachmentsNumber: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(0.25),
  },
  sendButton: {
    opacity: '100%',
    transition: 'opacity 2s ease-in-out',
    backgroundColor: '#63cce9',
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: theme.spacing(0.5),
    '&:disabled': {
      opacity: '25%',
    },
    marginLeft: theme.spacing(2),
  },
}));

ChatInput.propTypes = {
  academy: PropTypes.string.isRequired,
  chatLoading: PropTypes.bool.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
};

export default connect(null, { sendChatMessage })(ChatInput);
