import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';

import { dotify } from '../../utils/functions';

import { commentOnPost } from '../../store/actions/postActions';

import {
  Typography,
  Box,
  InputBase,
  ButtonBase,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

import Avatar from '../../components/Avatar';

const PostComment = ({ author, content: fullContent, date }) => {
  const classes = useStyles();

  const initials = useMemo(() => {
    let initials = '';
    if (_.isString(author.firstName)) {
      initials.concat(author.firstName.charAt(0));
    }
    if (_.isString(author.lastName)) {
      initials.concat(author.lastName.charAt(0));
    }

    return initials;
  }, [author]);

  const shortContent = useMemo(() => dotify(fullContent, 150), [fullContent]);

  const [displayFull, setDisplayFull] = useState(false);
  const handleDisplayToggle = () => setDisplayFull(!displayFull);

  const displayedContent = useMemo(() => {
    return displayFull ? fullContent : shortContent;
  }, [displayFull, fullContent, shortContent]);

  const canToggle = useMemo(() => {
    return shortContent !== fullContent;
  }, [shortContent, fullContent]);

  return (
    <Box mt={4} display="flex">
      <Box pr={2}>
        <Avatar
          size="extraSmall"
          imgSrc={author.avatar ? author.avatar.url : null}
          borderColor="black"
          initials={initials}
        />
      </Box>

      <Box flexGrow={1}>
        <Box mb={2}>
          <Typography component="span" className={classes.commentAuthor}>
            {`${author.firstName} ${author.lastName}`}
          </Typography>

          <Typography component="span" className={classes.commentDate}>
            {date}
          </Typography>
        </Box>

        <Typography className={classes.commentMessage}>
          {displayedContent}

          {canToggle ? (
            <span
              onClick={handleDisplayToggle}
              className={classes.displayToggle}
            >
              {displayFull ? 'Show less' : 'Show more'}
            </span>
          ) : null}
        </Typography>
      </Box>
    </Box>
  );
};

PostComment.propTypes = {
  author: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

const PostComments = ({ postId, comments, commentOnPost }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const handleCommentChange = e => {
    setComment(e.target.value);
  };

  const handleComment = async e => {
    e.preventDefault();

    setLoading(true);
    await commentOnPost({ postId, comment });
    setLoading(false);

    setComment('');
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleComment}>
        <InputBase
          className={classes.commentInput}
          name="comment"
          type="text"
          value={comment}
          onChange={handleCommentChange}
          placeholder="Type a new message"
          fullWidth
          required
          disabled={loading}
          endAdornment={
            <InputAdornment position="end">
              <ButtonBase
                type="submit"
                disabled={loading}
                className={classes.sendCommentButton}
              >
                <SendIcon />
              </ButtonBase>
            </InputAdornment>
          }
        />{' '}
      </form>

      {loading ? (
        <Box display="flex" justifyContent="center" py={2}>
          <CircularProgress />
        </Box>
      ) : null}

      {Array.isArray(comments) && comments.length > 0 ? (
        comments.map(comment => (
          <PostComment
            key={`comment_${comment.id}`}
            author={comment.user}
            content={comment.content}
            date={moment(comment.created_at).format('DD/MM/YYYY')}
          />
        ))
      ) : (
        <Box py={2} width="100%">
          <Typography align="center">
            {'No comments yet. Be the first to comment!'}
          </Typography>
        </Box>
      )}
    </div>
  );
};

PostComments.propTypes = {
  postId: PropTypes.number.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '6px',
    borderTop: '24px solid #f8f8f8',
    borderRight: '24px solid #f8f8f8',
    borderLeft: '24px solid #f8f8f8',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      border: 0,
    },
    padding: '34px',
    fontSize: '16px',
  },
  commentInput: {
    border: '1px solid #979797',
    backgroundColor: '#f8f8f8',
    borderRadius: '8px',
    padding: '16px',
  },
  sendCommentButton: {
    backgroundColor: '#63cce9',
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: theme.spacing(0.5),
  },
  commentAuthor: {
    fontWeight: theme.typography.fontWeightBold,
  },
  commentDate: {
    marginLeft: theme.spacing(2),
    opacity: '25%',
    fontSize: '12px',
  },
  commentMessage: {
    padding: '26px 24px 40px',
    backgroundColor: '#f8f8f8',
  },
  displayToggle: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

export default connect(null, { commentOnPost })(PostComments);
