import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { Box, Typography } from '@material-ui/core';

import Button from '../Button';
import UploadSuccessToast from './UploadSuccessToast';
import PDFViewer from '../utils/PDFViewer';

const UploadSuccessModal = ({
  onClose,
  redirectToSlug,
  imgSrc,
  pdfSrc,
  modalTitle,
  contents,
  activityName,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const ref = React.createRef();

  const handleAction = () => {
    onClose();

    enqueueSnackbar(null, {
      autoHideDuration: 7000,
      anchorOrigin: { horizontal: 'right', vertical: 'top' },
      content: () => (
        <UploadSuccessToast
          ref={ref}
          onClose={() => closeSnackbar()}
          redirectToSlug={redirectToSlug}
          imgSrc={imgSrc}
          pdfSrc={pdfSrc}
          uploadTitle={modalTitle}
          activityName={activityName}
          history={history}
        />
      ),
      onClose: () => closeSnackbar(),
    });
  };

  return (
    <div>
      <div className={classes.container}>
        {imgSrc ? (
          <img
            alt={'Uploaded content preview'}
            src={imgSrc}
            className={classes.image}
          />
        ) : pdfSrc ? (
          <div className={classes.pdfPreview}>
            <PDFViewer url={pdfSrc} disableControls />
          </div>
        ) : null}

        <div>
          <Box>
            <Typography className={classes.label}>{'Title'}</Typography>
            <Typography className={classes.text}>{modalTitle}</Typography>
          </Box>

          {contents}
        </div>
      </div>

      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button onClick={handleAction} variant="primary" size="small">
          {'Continue'}
        </Button>
      </Box>
    </div>
  );
};

UploadSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectToSlug: PropTypes.string,
  imgSrc: PropTypes.string,
  pdfSrc: PropTypes.string,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  contents: PropTypes.node,
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    maxHeight: '500px',
    objectFit: 'cover',
    marginRight: 0,
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      width: '30%',
      marginRight: '30px',
      marginBottom: 0,
    },
  },
  pdfPreview: {
    maxHeight: '500px',
    marginRight: 0,
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      width: '30%',
      marginRight: '30px',
      marginBottom: 0,
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    fontSize: '16px',
  },
}));

export default UploadSuccessModal;
