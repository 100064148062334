import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { setAvatar } from '../store/actions/userActions';

import Avatar from './Avatar';

const UploadAvatar = ({ imgSrc, ...props }) => {
  const classes = useStyles();

  const handleFileChange = event => {
    props.setAvatar(event.target.files[0]);
  };
  const file = React.useRef(null);

  const onAvatarClick = () => {
    file.current.click();
  };

  return (
    <div>
      <input
        accept="image/*"
        hidden
        type="file"
        ref={file}
        onChange={event => handleFileChange(event)}
      />
      <div onClick={onAvatarClick} className={classes.container}>
        <Avatar imgSrc={imgSrc} size="big" />
        <span className={classes.title}>Change profile picture</span>
      </div>
    </div>
  );
};

UploadAvatar.propTypes = {
  imgSrc: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  title: {
    fontWeight: 'bold',
  },
});

export default connect(null, {
  setAvatar,
})(UploadAvatar);
