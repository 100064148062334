import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { IconButton } from '@material-ui/core';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';

const FileCard = ({ className, width, height, title, onRemove }) => {
  const classes = useStyles({ width, height });

  return (
    <div className={clsx(classes.root, className)}>
      <FileIcon className={classes.icon} />
      <span className={classes.title}>{title}</span>

      {onRemove ? (
        <IconButton
          className={classes.closeButton}
          size="small"
          onClick={onRemove}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
    </div>
  );
};

FileCard.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  root: ({ width, height }) => ({
    width: width ? width : '100%',
    height: height ? height : '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #d4d4d4',
    borderRadius: '4px',
    position: 'relative',
    padding: theme.spacing(1),
    overflow: 'hidden',
  }),
  icon: {
    fontSize: '56px',
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: '12px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  closeIcon: {
    fontSize: '12px',
  },
}));

export default FileCard;
