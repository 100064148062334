import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { studentTabs } from '../../config/tabs.config';

import {
  getSessions,
  uploadThirdPhaseStuff,
} from '../../store/actions/studentActions';

import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Header from '../../components/Header';
import TabNavigation from '../../components/tabNavigation';
import Dialog from '../../components/Dialog';

import UploadSuccessModal from '../../components/PostUploads/UploadSuccessModal';

import PhaseHeader from './secondThirdPhasesComponents/PhaseHeader';
import UploadButtonGrid from './secondThirdPhasesComponents/UploadButtonGrid';
import UploadForm from './secondThirdPhasesComponents/UploadForm';
import ImageUploadItem from './secondThirdPhasesComponents/contentItems/ImageUploadItem';
import UploadItem from './secondThirdPhasesComponents/contentItems/UploadItem';

const StudentDashboard = ({
  currentUser,
  userProgress,
  getSessions,
  sessions,
  uploadThirdPhaseStuff,
  currentUpload,
}) => {
  const classes = useStyles();

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const handleUploadModalClose = () => setIsUploadModalOpen(false);
  const handleUploadModalOpen = () => setIsUploadModalOpen(true);

  const handleSuccessModalClose = () => setIsSuccessModalOpen(false);
  const handleSuccessModalOpen = () => setIsSuccessModalOpen(true);

  useEffect(() => {
    getSessions();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async values => {
    const uploadData = {
      data: {
        title: values.title,
        content: values.content,
        shareWith: 'me',
        category: 'phase3',
        activityId: activeId,
      },
      attachments: values.attachments,
    };

    const res = await uploadThirdPhaseStuff(uploadData);

    if (res) {
      handleSuccessModalOpen();
    }

    getSessions();
  };

  const getImageSrcFromUpload = upload => {
    if (upload && upload.attachments.length) {
      const img = upload.attachments.find(doc => doc.mime.includes('image'));

      if (img) {
        return img.url;
      }
    }

    return null;
  };

  const getPdfSrcFromUpload = upload => {
    if (!upload || !Array.isArray(upload.attachments)) {
      return null;
    }

    const pdfs = upload.attachments.reduce((acc, curr) => {
      if (curr.ext === '.pdf') {
        return [...acc, curr];
      } else {
        return acc;
      }
    }, []);

    return pdfs.length > 0 ? pdfs[0].url : null;
  };

  const isPageUnlocked = useMemo(() => {
    return (
      userProgress &&
      Array.isArray(userProgress.accessiblePhases) &&
      userProgress.accessiblePhases.includes('phase3')
    );
  }, [userProgress]);

  if (!isPageUnlocked) {
    return <Redirect to="/ypla/phase2" />;
  }

  return (
    <div>
      <Helmet>
        <title>{'Home'}</title>
      </Helmet>

      <Header user={currentUser} isWelcome />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <TabNavigation activeTab={'phase3'} memberType="ypla">
          <div className={classes.container}>
            <Typography className={classes.title}>
              {studentTabs.phase3.title}
            </Typography>
            <Typography className={classes.subtitle}>
              {studentTabs.phase3.subtitle}
            </Typography>

            {sessions.map(session => (
              <div key={session.id} className={classes.sessionContainer}>
                {/* Session header */}
                <PhaseHeader
                  title={session.title}
                  subtitle={session.subtitle}
                  icon={session.icon && session.icon.url}
                  className={classes.phaseHeader}
                />

                {/* Upload button */}
                {session.upload_limit > session.uploads.length && (
                  <div className={classes.uploadItem}>
                    <UploadButtonGrid
                      onClick={() => {
                        handleUploadModalOpen();
                        setActiveId(session.id);
                      }}
                      number={1}
                    />
                  </div>
                )}

                {/* Uploads */}
                {session.uploads.map(upload => (
                  <div key={upload.id} className={classes.uploadItem}>
                    {getImageSrcFromUpload(upload) ? (
                      <ImageUploadItem
                        imageSource={getImageSrcFromUpload(upload)}
                        upload={upload}
                        activityName={session.title}
                      />
                    ) : (
                      <UploadItem
                        upload={upload}
                        activityName={session.title}
                      />
                    )}
                  </div>
                ))}

                {/* Upload dialog */}
                <Dialog
                  open={isUploadModalOpen}
                  onClose={() => {
                    handleUploadModalClose();
                    setActiveId(null);
                  }}
                  maxWidth="md"
                  fullWidth
                  scroll="body"
                  classes={{
                    paper: classes.dialog,
                  }}
                >
                  <DialogTitle className={classes.dialogTitle}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography className={classes.dialogTitleText}>
                        {'Upload'}
                      </Typography>

                      <IconButton onClick={handleUploadModalClose}>
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </DialogTitle>

                  <DialogContent className={classes.dialogContent}>
                    <UploadForm
                      handleUploadModalClose={handleUploadModalClose}
                      onSubmit={onSubmit}
                    />
                  </DialogContent>
                </Dialog>

                {/* Success dialog */}
                <Dialog
                  open={isSuccessModalOpen}
                  onClose={() => {
                    handleSuccessModalClose();
                    setActiveId(null);
                  }}
                  maxWidth="md"
                  fullWidth
                  scroll="body"
                  classes={{
                    paper: classes.dialog,
                  }}
                >
                  <DialogTitle className={classes.dialogTitle}>
                    <Typography className={classes.dialogTitleText}>
                      {'Success! Your file has been uploaded'}
                    </Typography>
                  </DialogTitle>

                  <DialogContent className={classes.dialogContent}>
                    <UploadSuccessModal
                      onClose={handleSuccessModalClose}
                      redirectToSlug={currentUpload && currentUpload.slug}
                      imgSrc={getImageSrcFromUpload(currentUpload)}
                      pdfSrc={getPdfSrcFromUpload(currentUpload)}
                      modalTitle={currentUpload && currentUpload.title}
                      contents={
                        currentUpload && currentUpload.content ? (
                          <Box mt={4}>
                            <Typography className={classes.label}>
                              {'Description'}
                            </Typography>
                            <Typography className={classes.text}>
                              {currentUpload.content}
                            </Typography>
                          </Box>
                        ) : null
                      }
                      activityName={session.title}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            ))}
          </div>
        </TabNavigation>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    lineHeight: '43px',
    fontWeight: 'bold',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginBottom: '4px',
      lineHeight: '26px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  container: {
    paddingTop: '30px',
    paddingBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '742px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1090px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  sessionContainer: {
    marginBottom: '56px',
  },
  phaseHeader: {
    marginBottom: '32px',
  },
  dialog: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogTitle: {
    padding: theme.spacing(2),
  },
  dialogTitleText: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  uploadItem: {
    marginBottom: '20px',
  },
  label: {
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    fontSize: '16px',
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  userProgress: state.user.userProgress,
  sessions: state.student.sessions,
  currentUpload: state.student.currentUpload,
});

export default connect(mapStateToProps, {
  getSessions,
  uploadThirdPhaseStuff,
})(StudentDashboard);
