import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { ButtonBase, Box, Typography, IconButton } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from '@material-ui/icons/Close';

import Avatar from '../../components/Avatar';

const MembersButton = ({ members, academy }) => {
  const classes = useStyles();

  const [isMembersModalOpen, setIsMembersModalOpen] = useState(false);

  const handleMembersModalToggle = () =>
    setIsMembersModalOpen(!isMembersModalOpen);
  const handleMembersModalClose = () => setIsMembersModalOpen(false);

  const membersCount = useMemo(() => {
    return Array.isArray(members) ? members.length : 0;
  }, [members]);

  return (
    <>
      <ButtonBase
        className={classes.membersButton}
        onClick={handleMembersModalToggle}
      >
        <GroupIcon className={classes.membersButtonIcon} />

        <span className={classes.membersButtonCount}>{membersCount}</span>

        {' Columbans'}
      </ButtonBase>

      {isMembersModalOpen ? (
        <div className={classes.membersModal}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.modalTitle}>{`Columbans in your ${
              academy === 'htla' ? 'cohort' : 'group'
            }`}</Typography>

            <IconButton onClick={handleMembersModalClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>

          <PerfectScrollbar>
            <Box height="100%">
              {members.map(member => (
                <Box
                  key={member.id}
                  my={4}
                  display="flex"
                  component={academy === 'htla' ? Link : 'div'}
                  to={`/users/${member.id}`}
                  className={classes.memberContainer}
                >
                  <Box pl={0.5} pr={0.5}>
                    <Avatar
                      size="small"
                      imgSrc={member.avatar ? member.avatar.url : null}
                      borderColor="black"
                      initials={`${
                        _.isString(member.firstName)
                          ? member.firstName.charAt(0)
                          : ''
                      }${
                        _.isString(member.lastName)
                          ? member.lastName.charAt(0)
                          : ''
                      }`}
                    />
                  </Box>

                  <Box ml={2} flexGrow={1}>
                    <Typography className={classes.memberName}>
                      {member.firstName} {member.lastname}
                    </Typography>

                    {academy === 'htla' ? (
                      <Typography className={classes.memberInfo}>
                        {member.jobTitle} {member.school}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              ))}
            </Box>
          </PerfectScrollbar>
        </div>
      ) : null}
    </>
  );
};

MembersButton.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
  academy: PropTypes.string.isRequired,
};

MembersButton.defaultProps = {
  members: [],
};

const useStyles = makeStyles(theme => ({
  membersButton: {
    borderRadius: '5px',
    border: '1px solid #000000',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    fontSize: '15px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  },
  membersButtonCount: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  membersButtonIcon: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
  },
  membersModal: {
    backgroundColor: theme.palette.common.white,
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '280px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
    height: '80vh',
    zIndex: 9999,
    boxShadow: '0px 0px 80px -20px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  modalTitle: {
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  memberContainer: {
    textDecoration: 'none',
    color: 'inherit',
  },
  memberName: {
    fontWeight: theme.typography.fontWeightBold,
  },
  memberInfo: {
    fontSize: '12px',
  },
}));

export default MembersButton;
