import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { Box, Container } from '@material-ui/core';

import Header from '../../components/Header';
import Chat from './Chat';

const GroupChat = ({ currentUser }) => {
  const classes = useStyles();

  const academy = useMemo(() => {
    if (currentUser?.role?.type === 'htla_member') {
      return 'htla';
    }

    if (currentUser?.role?.type === 'stla_member') {
      return 'stla';
    }
    return 'ypla';
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{'My group'}</title>
      </Helmet>

      <Header user={currentUser} />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <Box bgcolor="#f8f8f8" flex={1} overflow="hidden">
          <Container maxWidth="md" disableGutters className={classes.container}>
            <Chat academy={academy} />
          </Container>
        </Box>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  container: {
    height: '100%',
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
});

export default connect(mapStateToProps)(GroupChat);
