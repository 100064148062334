import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
} from '../actionTypes/snackbarActionTypes';

export const enqueueSnackbar = notification => {
  return {
    type: ENQUEUE_SNACKBAR,
    payload: notification,
  };
};

export const closeSnackbar = () => dispatch => {
  dispatch({
    type: CLOSE_SNACKBAR,
  });
};
