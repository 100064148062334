import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/utils/ErrorFallback';
import { logBoundaryError } from './utils/functions';

import { getUser } from './store/actions/userActions';

import Loader from './components/Loader';
import ProtectedRoute from './components/utils/ProtectedRoute';
import Notifier from './components/utils/Notifier';

import SignInPage from './pages/SignIn';
import SignUpPage from './pages/SignUp';
import ForgotPasswordPage from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPassword';
import StudentDashboardPage from './pages/studentDashboard';
import TeacherDashboardPage from './pages/teacherDashboard';
import FocusPage from './pages/teacherDashboard/focus';
import CreativityPage from './pages/teacherDashboard/creativity';
import IntegrityPage from './pages/teacherDashboard/integrity';
import PerseverancePage from './pages/teacherDashboard/perseverance';
import ServicePage from './pages/teacherDashboard/service';
import SecondPhasePage from './pages/studentDashboard/secondPhase';
import ThirdPhasePage from './pages/studentDashboard/thirdPhase';
import AlumniPhasePage from './pages/studentDashboard/alumni';
import PostPage from './pages/Post';
import SearchPage from './pages/Search';
import GroupChatPage from './pages/GroupChat';
import MyColumbaPage from './pages/MyColumba';
import UserProfilePage from './pages/UserProfile';
import NoAccessPage from './pages/NoAccess';

import theme from './theme';

const App = ({ getUser, currentUser, userProgress }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('jwtToken')) {
        await getUser();
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <SnackbarProvider maxSnack={3}>
        <HelmetProvider>
          <BrowserRouter>
            <Helmet
              titleTemplate="%s | Columba 1400"
              defaultTitle="Columba 1400"
            />

            <Notifier />

            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={logBoundaryError}
            >
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    if (currentUser) {
                      if (currentUser.role.type === 'htla_member') {
                        return <Redirect to="/htla" />;
                      }

                      if (currentUser.role.type === 'stla_member') {
                        return <Redirect to="/stla" />;
                      }

                      if (userProgress && userProgress.currentPhase) {
                        const actualYplaUrl =
                          userProgress.currentPhase === 'phase1'
                            ? '/ypla'
                            : `/ypla/${userProgress.currentPhase}`;

                        return <Redirect to={actualYplaUrl} />;
                      } else {
                        return <Redirect to="/ypla" />;
                      }
                    } else {
                      return <Redirect to="/sign-in" />;
                    }
                  }}
                />
                <Route exact path="/sign-in" component={SignInPage} />
                <Route exact path="/register/:academy" component={SignUpPage} />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPasswordPage}
                />
                <Route
                  exact
                  path="/resetpassword"
                  component={ResetPasswordPage}
                />
                <Route exact path="/no-access" component={NoAccessPage} />
                <ProtectedRoute
                  exact
                  path="/ypla"
                  component={StudentDashboardPage}
                  allowedFor={['ypla_member']}
                />
                <ProtectedRoute
                  exact
                  path="/ypla/phase2"
                  component={SecondPhasePage}
                  allowedFor={['ypla_member']}
                />
                <ProtectedRoute
                  exact
                  path="/ypla/phase3"
                  component={ThirdPhasePage}
                  allowedFor={['ypla_member']}
                />
                <ProtectedRoute
                  exact
                  path="/ypla/alumni"
                  component={AlumniPhasePage}
                  allowedFor={['ypla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla', '/stla']}
                  component={TeacherDashboardPage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/focus', '/stla/focus']}
                  component={FocusPage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/creativity', '/stla/creativity']}
                  component={CreativityPage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/integrity', '/stla/integrity']}
                  component={IntegrityPage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/perseverance', '/stla/perseverance']}
                  component={PerseverancePage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/service', '/stla/service']}
                  component={ServicePage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/group', '/ypla/group', '/stla/group']}
                  component={GroupChatPage}
                  allowedFor={['htla_member', 'ypla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path="/users/:id"
                  component={UserProfilePage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/htla/mycolumba', '/stla/mycolumba']}
                  component={MyColumbaPage}
                  allowedFor={['htla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path={['/search', '/search/:searchTerm']}
                  component={SearchPage}
                  allowedFor={['htla_member', 'ypla_member', 'stla_member']}
                />
                <ProtectedRoute
                  exact
                  path="/posts/:slug"
                  component={PostPage}
                  allowedFor={['htla_member', 'ypla_member', 'stla_member']}
                />
                <Route exact path="*" component={NoAccessPage} />
              </Switch>
            </ErrorBoundary>
          </BrowserRouter>
        </HelmetProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  userProgress: state.user.userProgress,
});

export default connect(mapStateToProps, { getUser })(App);
