import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { milk } from '../config/colors.config';

import { updateProfile } from '../store/actions/userActions';

import { Box, TextField } from '@material-ui/core';

import PasswordInput from './inputs/PasswordInput';
import Button from './Button';
import UploadButton from './UploadButton';
import UploadAvatar from './UploadAvatar';

const EditProfileForm = ({ updateProfile, currentUser, avatar, close }) => {
  const classes = useStyles();

  const isHTLA =
    currentUser?.role?.type === 'htla_member' ||
    currentUser?.role?.type === 'stla_member';

  const textFields = useMemo(() => {
    return {
      firstName: 'First name',
      lastName: 'Last name',
      school: 'School',
      ...(isHTLA
        ? {
            jobTitle: 'Job title',
          }
        : null),
    };
  }, [isHTLA]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      firstName: Yup.string().required().label('First name'),
      lastName: Yup.string().required().label('Last name'),
      school: Yup.string().required().label('School'),
      ...(isHTLA
        ? { jobTitle: Yup.string().required().label('Job title') }
        : null),
      password: Yup.string()
        .label('Password')
        .min(6, 'Minimum password length is at least a value of 6'),
      confirmPassword: Yup.string()
        .label('Confirm password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
  }, [isHTLA]);

  const initialValues = useMemo(() => {
    return {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      school: currentUser.school,
      jobTitle: currentUser.jobTitle,
      password: '',
      confirmPassword: '',
    };
  }, [currentUser]);

  const onSubmit = async (values, { setSubmitting }) => {
    const userData = {
      firstName: values.firstName,
      lastName: values.lastName,
      school: values.school,
      ...(isHTLA ? { jobTitle: values.jobTitle } : null),
      ...(values.password ? { password: values.password } : null),
    };

    await updateProfile({ data: userData, avatar });

    setSubmitting(false);
    close();
  };

  const getTextField = (
    label,
    name,
    errors,
    touched,
    values,
    handleChange,
    handleBlur
  ) => {
    const error = touched[name] && errors[name] ? errors[name] : false;

    return (
      <div key={name} className={classes.formInput}>
        <TextField
          fullWidth
          name={name}
          variant="outlined"
          label={label}
          value={values[name]}
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
          error={!!error}
          helperText={error}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.uploadButton}>
        {avatar || (currentUser.avatar && currentUser.avatar.url) ? (
          <UploadAvatar
            imgSrc={
              avatar ? URL.createObjectURL(avatar) : currentUser.avatar.url
            }
          />
        ) : (
          <UploadButton />
        )}
      </div>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
          dirty,
        }) => (
          <Form className={classes.form}>
            <div>
              {Object.keys(textFields).map(name => {
                return getTextField(
                  textFields[name],
                  name,
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleBlur
                );
              })}
            </div>

            <div className={classes.formInput}>
              <PasswordInput
                fullWidth
                name="password"
                value={values.password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                error={
                  touched.password && errors.password ? errors.password : false
                }
              />
            </div>

            <div className={classes.formInput}>
              <PasswordInput
                isConfirm={true}
                onBlur={handleBlur('confirmPassword')}
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange('confirmPassword')}
                error={
                  touched.confirmPassword && errors.confirmPassword
                    ? errors.confirmPassword
                    : false
                }
              />
            </div>

            <Box mt={4} className={classes.button}>
              <Button
                type="submit"
                fullWidth
                disabled={
                  isSubmitting ||
                  (Boolean(values.password) &&
                    !Boolean(values.confirmPassword)) ||
                  (Boolean(values.confirmPassword) && !Boolean(values.password))
                }
                isLoading={isSubmitting}
              >
                Save Changes
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditProfileForm.propTypes = {
  close: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 40px 40px',
  },
  formInput: {
    marginBottom: '12px',
  },
  logoImage: {
    width: '291px',
  },
  uploadButton: {
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: milk,
    width: '100%',
    padding: '20px 0',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  },
}));

const mapStateToProps = state => ({
  avatar: state.user.avatar,
  currentUser: state.user.currentUserData,
});

export default connect(mapStateToProps, {
  updateProfile,
})(EditProfileForm);
