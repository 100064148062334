import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import shortid from 'shortid';

import { getChatContents, resetQuery } from '../../store/actions/chatActions';
import { getGroupMembers } from '../../store/actions/groupActions';

import {
  Box,
  ButtonBase,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import ChatItem from './ChatItem';
import ChatInput from './ChatInput';
import MembersButton from './MembersButton';

const Chat = ({
  getChatContents,
  resetQuery,
  getGroupMembers,
  chatResults,
  chatLoading,
  chatStart,
  chatHasMore,
  groupMembers,
  academy,
}) => {
  const classes = useStyles();

  const chatContentElement = useRef(null);

  useEffect(() => {
    (async () => {
      await getChatContents();

      if (chatStart === 0) {
        scrollToBottom();
      }
    })();

    return () => resetQuery();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getGroupMembers();
    // eslint-disable-next-line
  }, []);

  const scrollToBottom = () => {
    if (chatContentElement && chatContentElement.current) {
      chatContentElement.current.scrollTop =
        chatContentElement.current.scrollHeight;
    }
  };

  const title = useMemo(() => {
    return academy === 'htla' || academy === 'stla'
      ? 'My Cohort Channel'
      : 'My Group Channel';
  }, [academy]);
  const subtitle = useMemo(() => {
    return academy === 'htla' || academy === 'stla'
      ? 'Welcome to the cohort channel!'
      : 'Welcome to your group!';
  }, [academy]);

  return (
    <Box component="main" className={classes.root}>
      {/* Header */}
      <Box className={classes.header}>
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </Box>

        <MembersButton members={groupMembers} academy={academy} />
      </Box>

      {/* Contents */}
      <Box className={classes.contents}>
        {chatLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className={classes.results} ref={chatContentElement}>
            {Array.isArray(chatResults) && chatResults.length > 0 ? (
              <>
                {chatHasMore ? (
                  <Box display="flex" justifyContent="center" py={4}>
                    <ButtonBase
                      onClick={() => getChatContents()}
                      className={classes.viewOlderButton}
                    >
                      {'View older activity'}
                    </ButtonBase>
                  </Box>
                ) : null}

                {chatResults.map(result => (
                  <ChatItem key={`chat_${shortid.generate()}`} data={result} />
                ))}
              </>
            ) : (
              <Box display="flex" justifyContent="center" pt={4}>
                <Typography variant="h6">
                  {'No activity in your group yet.'}
                </Typography>
              </Box>
            )}
          </div>
        )}

        {/* Input bar */}
        <ChatInput
          academy={academy}
          chatLoading={chatLoading}
          scrollToBottom={scrollToBottom}
        />
      </Box>
    </Box>
  );
};

Chat.propTypes = {
  academy: PropTypes.oneOf(['htla', 'ypla', 'stla']).isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  header: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    boxShadow: '0px 10px 35px -20px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '20px',
  },
  subtitle: {
    opacity: '50%',
    fontSize: '16px',
  },
  contents: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: '100px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: '66px',
    },
  },
  results: {
    height: '100%',
    overflowY: 'scroll',
  },
  viewOlderButton: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.1rem',
    border: '1px solid black',
    borderRadius: '35px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

const mapStateToProps = state => ({
  chatResults: state.chat.results,
  chatLoading: state.chat.loading,
  chatStart: state.chat.start,
  chatHasMore: state.chat.hasMore,
  groupMembers: state.group.groupMembers,
});

export default connect(mapStateToProps, {
  getChatContents,
  resetQuery,
  getGroupMembers,
})(Chat);
