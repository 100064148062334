import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { Typography, Hidden } from '@material-ui/core';

import ProgressBar from './ProgressBar';

const PhaseHeader = ({ icon, title, subtitle, date, className, progress }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {date ? (
        <div className={classes.dateWrapper}>
          <div className={classes.hr} />
          <div className={classes.date}>
            {moment(date).format('dddd, MMMM Do')}
          </div>
        </div>
      ) : (
        <div className={classes.line} />
      )}

      <div className={classes.container}>
        <div className={classes.flex}>
          <img
            alt="Activity icon"
            src={icon}
            className={classes.activityIcon}
          />

          <div className={classes.titleContainer}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </div>
        </div>

        <Hidden smDown>
          {progress !== undefined && <ProgressBar progress={progress} />}
        </Hidden>
      </div>

      <Hidden mdUp>
        <div className={classes.mobileProgressBar}>
          {progress !== undefined && <ProgressBar progress={progress} />}
        </div>
      </Hidden>
    </div>
  );
};

PhaseHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  date: PropTypes.string,
  className: PropTypes.string,
  progressBar: PropTypes.number,
};

const useStyles = makeStyles(theme => ({
  line: {
    height: '1px',
    width: '100%',
    backgroundColor: 'black',
  },
  date: {
    border: '1px solid black',
    borderRadius: '19px',
    display: 'inline-flex',
    padding: '7px 22px 9px 22px',
    fontSize: '16px',
    lineHeight: '22px',
    position: 'relative',
    zIndex: 1,
    backgroundColor: 'white',
  },
  dateWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  hr: {
    height: 1,
    position: 'absolute',
    right: 0,
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'black',
  },
  container: {
    marginTop: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '32px',
    lineHeight: '43px',
    fontWeight: 'bold',
    marginBottom: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '21px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  titleContainer: {
    marginLeft: '20px',
  },
  mobileProgressBar: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  flex: {
    display: 'flex',
  },
  activityIcon: {
    width: 70,
    height: 70,
    objectFit: 'cover',
  },
}));

export default PhaseHeader;
