import { combineReducers } from 'redux';

import user from './userReducer';
import snackbar from './snackbarReducer';
import posts from './postReducer';
import search from './searchReducer';
import postCategory from './postCategoryReducer';
import student from './studentReducer';
import group from './groupReducer';
import myColumba from './myColumbaReducer';
import chat from './chatReducer';

export default combineReducers({
  user,
  snackbar,
  posts,
  search,
  postCategory,
  student,
  group,
  myColumba,
  chat,
});
