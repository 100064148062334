import { useLocation } from 'react-router-dom';

export function useUserType() {
  const slug = useLocation();

  if (slug.pathname.match(/stla/)) {
    return 'stla';
  }

  if (slug.pathname.match(/htla/)) {
    return 'htla';
  }

  return 'ypla';
}
