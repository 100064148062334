import { captureException } from '@sentry/react';

import ApiClient from '../../utils/ApiClient';
import { getErrorMessage } from '../../utils/functions';

import { SEARCH_RESULTS_LIMIT } from '../../config/request.config';

import {
  SET_RESULTS,
  SET_START,
  SET_HAS_MORE,
} from '../actionTypes/searchActionTypes';
import { enqueueSnackbar } from './snackbarActions';

export const getSearchResults = ({ start, searchTerm }) => {
  return async dispatch => {
    try {
      dispatch(setHasMore(false));

      const response = await ApiClient.post('/search', null, {
        params: {
          _sort: 'updated_at:DESC',
          _start: start,
          _limit: SEARCH_RESULTS_LIMIT,
          searchTerm,
        },
      });

      dispatch({
        type: SET_RESULTS,
        payload: {
          reset: start === 0,
          results: response.data,
        },
      });

      if (response.data.length === SEARCH_RESULTS_LIMIT) {
        dispatch(setHasMore(true));
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        })
      );

      captureException(error);
    }
  };
};

export const setStart = start => {
  return async dispatch => {
    dispatch({
      type: SET_START,
      payload: start,
    });
  };
};

const setHasMore = value => {
  return {
    type: SET_HAS_MORE,
    payload: value,
  };
};
