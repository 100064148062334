import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Dialog as MuiDialog } from '@material-ui/core';

const Dialog = ({ className, children, open, onClose, ...rest }) => {
  const classes = useStyles();

  return (
    <MuiDialog
      className={clsx(classes.root, className)}
      open={open}
      onClose={onClose}
      {...rest}
    >
      {children}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {},
  body: {},
}));

export default Dialog;
