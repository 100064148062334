import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { captureException } from '@sentry/react';
import { useDebounce } from 'use-debounce';
import { uniq } from 'lodash';

import ApiClient from '../../utils/ApiClient';

import { Container, ButtonBase } from '@material-ui/core';

const SearchCompletions = ({ searchTerm, handleSearch }) => {
  const classes = useStyles();
  const [debouncedSearchTerm] = useDebounce(searchTerm, 50);

  const [completions, setCompletions] = useState([]);

  const validSearch = useMemo(() => {
    return (
      searchTerm && typeof searchTerm == 'string' && searchTerm.length >= 3
    );
  }, [searchTerm]);

  useEffect(() => {
    let isMounted = true;

    if (!validSearch) {
      setCompletions([]);
      return;
    }

    (async () => {
      try {
        const response = await ApiClient.post('/search/completions', null, {
          params: { searchTerm: debouncedSearchTerm },
        });

        if (isMounted) {
          setCompletions(
            Array.isArray(response.data) ? uniq(response.data) : []
          );
        }
      } catch (error) {
        captureException(error);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [debouncedSearchTerm, validSearch]);

  if (!validSearch || !Array.isArray(completions) || completions.length < 1) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Container fixed>
        {completions.map((completion, idx) => {
          const searchTermLower = searchTerm.toLowerCase();
          const completionLower = completion.toLowerCase();

          const before = completion.slice(
            0,
            completionLower.indexOf(searchTermLower)
          );
          const between = completion.slice(
            completionLower.indexOf(searchTermLower),
            completionLower.indexOf(searchTermLower) + searchTermLower.length
          );
          const after = completion.slice(
            completionLower.indexOf(searchTermLower) + searchTermLower.length,
            completionLower.length
          );

          return (
            <ButtonBase
              key={`completion_${idx}`}
              className={classes.completion}
              onClick={() => handleSearch(completion)}
              disableRipple
              disableTouchRipple
            >
              {completionLower.includes(searchTermLower) ? (
                <>
                  {before}
                  <b>{between}</b>
                  {after}
                </>
              ) : (
                completion
              )}
            </ButtonBase>
          );
        })}
      </Container>
    </div>
  );
};

SearchCompletions.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: '1px solid #dbdbdb',
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  completion: {
    display: 'block',
    width: '100%',
    lineHeight: 1.5,
    textAlign: 'left',
    fontSize: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.35rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
    marginBottom: theme.spacing(0.5),
    '&:hover': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export default SearchCompletions;
