import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';

import { setAvatar } from '../store/actions/userActions';

import { Box, TextField, Typography } from '@material-ui/core';

import PasswordInput from './inputs/PasswordInput';
import Button from './Button';
import UploadButton from './UploadButton';
import UploadAvatar from './UploadAvatar';

const AuthForm = ({
  title,
  type,
  memberType,
  initialValues,
  validationSchema,
  onSubmit,
  ...props
}) => {
  const classes = useStyles(props);

  let textFields = {
    email: 'Email address',
  };
  if (type === 'sign up') {
    textFields = {
      firstName: 'First name',
      lastName: 'Last name',
      ...textFields,
      school: 'School',
    };
  }
  if (memberType === 'htla' || memberType === 'stla') {
    textFields = {
      ...textFields,
      jobTitle: 'Job title',
    };
  }

  const getTextField = (
    label,
    name,
    errors,
    touched,
    values,
    handleChange,
    handleBlur
  ) => {
    const error = touched[name] && errors[name] ? errors[name] : false;
    return (
      <div key={name} className={classes.formInput}>
        <TextField
          fullWidth
          name={name}
          variant="outlined"
          label={label}
          value={values[name]}
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
          error={!!error}
          helperText={error}
        />
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Typography align="center" variant="h5" className={classes.title}>
        {title}
      </Typography>
      {type === 'sign up' && (
        <div className={classes.uploadButton}>
          {props.avatar ? (
            <UploadAvatar imgSrc={URL.createObjectURL(props.avatar)} />
          ) : (
            <UploadButton />
          )}
        </div>
      )}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form className={classes.form}>
            {type !== 'reset password' && (
              <div>
                {Object.keys(textFields).map(name => {
                  return getTextField(
                    textFields[name],
                    name,
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur
                  );
                })}
              </div>
            )}
            {type !== 'forgot password' && (
              <div className={classes.formInput}>
                <PasswordInput
                  fullWidth
                  name="password"
                  value={values.password}
                  onChange={handleChange('password')}
                  onBlur={handleBlur('password')}
                  error={
                    touched.password && errors.password
                      ? errors.password
                      : false
                  }
                />
              </div>
            )}
            {(type === 'sign up' || type === 'reset password') && (
              <div className={classes.formInput}>
                <PasswordInput
                  isConfirm={true}
                  onBlur={handleBlur('confirmPassword')}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  error={
                    touched.confirmPassword && errors.confirmPassword
                      ? errors.confirmPassword
                      : false
                  }
                />
              </div>
            )}
            {type === 'sign in' && (
              <Typography align="center">
                <Link to="/forgot-password" className={classes.link}>
                  Forgot password?
                </Link>
              </Typography>
            )}
            {type === 'forgot password' && (
              <Typography align="center">
                <Link to="/sign-in" className={classes.link}>
                  Sign in?
                </Link>
              </Typography>
            )}
            <Box mt={4}>
              <Button
                type="submit"
                isLoading={isSubmitting}
                fullWidth
                disabled={
                  !!Object.keys(errors).length ||
                  !Object.values(values)[0] ||
                  isSubmitting
                }
              >
                {title}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

AuthForm.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  memberType: PropTypes.string,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  title: {
    marginBottom: 32,
    fontWeight: 'bold',
  },
  link: {
    color: 'black',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formInput: {
    marginBottom: '12px',
  },
  logoImage: {
    width: '291px',
  },
  uploadButton: {
    marginBottom: 32,
    alignSelf: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const mapStateToProps = state => ({
  avatar: state.user.avatar,
});

export default connect(mapStateToProps, {
  setAvatar,
})(AuthForm);
