import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import { Redirect } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../components/utils/ErrorFallback';
import { logBoundaryError } from '../../utils/functions';

import { studentTabs } from '../../config/tabs.config';

import { getUser } from '../../store/actions/userActions';
import { getPostsByCategory, setStart } from '../../store/actions/postActions';

import { Typography } from '@material-ui/core';

import Header from '../../components/Header';
import TabNavigation from '../../components/tabNavigation/index';
import CardsGrid from '../../components/CardsGrid';

const StudentDashboard = props => {
  const {
    currentUser,
    userProgress,
    posts,
    setStart,
    getPostsByCategory,
  } = props;
  const classes = useStyles(props);

  const loadData = start =>
    getPostsByCategory({
      start,
      category: 'alumni',
      memberType: 'ypla',
      type: 'all',
    });

  useEffect(() => {
    loadData(0);
    setStart(0);
    // eslint-disable-next-line
  }, [setStart]);

  const isPageUnlocked = useMemo(() => {
    return (
      userProgress &&
      Array.isArray(userProgress.accessiblePhases) &&
      userProgress.accessiblePhases.includes('phase3')
    );
  }, [userProgress]);

  if (!isPageUnlocked) {
    return <Redirect to="/ypla/phase2" />;
  }

  return (
    <div>
      <Helmet>
        <title>{'Home'}</title>
      </Helmet>

      <Header user={currentUser} isWelcome />

      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={logBoundaryError}
      >
        <TabNavigation activeTab={'alumni'} memberType="ypla">
          <div className={classes.container}>
            <Typography className={classes.title}>
              {studentTabs.alumni.title}
            </Typography>
            <Typography className={classes.subtitle}>
              {studentTabs.alumni.subtitle}
            </Typography>

            <CardsGrid data={posts} loadMore={loadData} />
          </div>
        </TabNavigation>
      </ErrorBoundary>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '32px',
    lineHeight: '43px',
    fontWeight: 'bold',
    marginBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
      fontSize: '20px',
      marginBottom: '4px',
      lineHeight: '26px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '24px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px',
    },
  },
  container: {
    paddingTop: '30px',
    paddingBottom: '100px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '742px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1090px',
    },
  },
}));

const mapStateToProps = state => ({
  currentUser: state.user.currentUserData,
  userProgress: state.user.userProgress,
  posts: state.posts.posts,
});

export default connect(mapStateToProps, {
  getUser,
  getPostsByCategory,
  setStart,
})(StudentDashboard);
