import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { favouritePost, unfavouritePost } from '../store/actions/userActions';

import { IconButton, useMediaQuery } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';

const FavouriteIcon = ({ id, favourites, favouritePost, unfavouritePost }) => {
  const theme = useTheme();
  const xsAndDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [isFavoriting, setIsFavoriting] = useState(false);

  const isFavourite =
    Array.isArray(favourites) &&
    favourites.find(favourite => favourite.post && favourite.post.id === id);

  const classes = useStyles({ isFavourite });

  const onClick = async () => {
    setIsFavoriting(true);

    try {
      if (isFavourite) {
        await unfavouritePost(id);
      } else {
        await favouritePost(id);
      }
    } finally {
      setIsFavoriting(false);
    }
  };

  return (
    <IconButton
      onClick={onClick}
      disabled={isFavoriting}
      className={classes.root}
    >
      <Favorite fontSize={xsAndDown ? 'small' : 'large'} />
    </IconButton>
  );
};

FavouriteIcon.propTypes = {
  id: PropTypes.number,
};

const useStyles = makeStyles(() => ({
  root: ({ isFavourite }) => ({
    backgroundColor: 'black',
    '&:disabled': {
      backgroundColor: 'black',
      opacity: '50%',
    },
    '& > span': {
      color: isFavourite ? 'red' : 'white',
    },
    '&:hover': {
      backgroundColor: 'black',
      '& > span': {
        color: isFavourite ? 'white' : 'red',
      },
    },
  }),
}));

const mapStateToProps = state => ({
  favourites: state.user.favourites,
});

export default connect(mapStateToProps, {
  favouritePost,
  unfavouritePost,
})(FavouriteIcon);
