import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'emoji-mart/css/emoji-mart.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import store from './store';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://b4d24cde181e4723a4f15c97b1754db5@o439654.ingest.sentry.io/5407695',
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
